import router from '../router';
import * as Sentry from '@sentry/vue';

export default (app) => {
  if (import.meta.env.NODE_ENV === 'development') return;
  Sentry.init({
    app,
    release: import.meta.env.VITE_VERSION,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.VITE_ENVIRONMENT,
    integrations: [
      Sentry.browserTracingIntegration({
        router,
      }),
      Sentry.replayIntegration({
        maskAllText: false,
        maskAllInputs: false,
        blockAllMedia: false,
        networkDetailAllowUrls: [
          window.location.origin,
          'ats-api.proxify.io',
          'staging-ats-api.proxify.io',
          /^https:\/\/ats-api\.proxify\.io/,
        ],
      }),
    ],
    tracePropagationTargets: ['localhost', /^https:\/\/proxify\.io\/api/],
    trackComponents: true,
    hooks: ['activate', 'create', 'destroy', 'mount', 'update'],
    tracesSampleRate: 0.1,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
  const user = localStorage.getItem('user');
  if (user) {
    Sentry.setUser(JSON.parse(user));
  }
  const filterQueries = localStorage.getItem('filterQueries');
  if (filterQueries) {
    Sentry.addBreadcrumb({
      category: 'filters',
      message: filterQueries,
      level: 'info',
    });
  }
};
