<template>
  <div class="flex flex-col">
    <div class="text-proxify-black">{{ title }}</div>
    <div
      class="radio-wrapper"
      :class="{ disabled, 'read-only': readOnly }"
      @click="
        () =>
          !disabled &&
          handleChange(inputValue ? truthyValue + inputValue : truthyValue)
      "
    >
      <div class="radio-icon-wrapper">
        <div class="radio-icon-container">
          <IconBase
            :color="
              value === truthyValue || value === truthyValue + inputValue
                ? !disabled
                  ? '#5258FB'
                  : '#979797'
                : 'transparent'
            "
            border-radius="0.25rem"
            class="absolute inset-0 w-full h-full"
          >
            <IconRadio />
          </IconBase>
        </div>
      </div>
      <div
        :class="
          value === truthyValue ? 'text-proxify-black' : 'text-disabled-dark'
        "
      >
        {{ label }}
      </div>
    </div>
    <div v-if="label === 'Other:'">
      <AppFormTextField
        v-model="inputValue"
        :required="value === truthyValue"
        hide-label
        :disabled="disabled"
        :validate-on-mount="value === truthyValue"
        :label="`${name} ${label}`"
        class="ml-6"
        @update:model-value="
          (inputValue) => handleChange(truthyValue + inputValue)
        "
      />
    </div>
  </div>
</template>

<script>
import IconBase from '@/components/Icons/IconBase.vue';
import IconRadio from '@/components/Icons/IconRadio.vue';
import AppFormTextField from '@/components/App/form/AppFormTextField.vue';
import { reactive, toRefs } from 'vue';
import { useField } from 'vee-validate';

export default {
  name: 'AppFormRadio',
  components: { AppFormTextField, IconRadio, IconBase },
  props: {
    modelValue: {
      type: [Boolean, String, Number, Array],
      required: false,
    },
    initialValue: {
      type: [Boolean, String, Number, Array],
      required: false,
    },
    truthyValue: {
      type: [String, Boolean],
      required: false,
      default: true,
    },
    falsyValue: {
      type: [String, Boolean],
      required: false,
      default: false,
    },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: false,
    },
    required: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
    disabled: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
    readOnly: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
    hideHint: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
  },
  setup(props) {
    const { name, truthyValue, falsyValue, initialValue, required } =
      toRefs(props);
    const isOpenAnswered = truthyValue.value === 'Other:';
    const state = reactive({
      inputValue:
        isOpenAnswered &&
        initialValue.value &&
        initialValue.value.slice(0, 6) === 'Other:'
          ? initialValue.value.slice(6)
          : '',
    });
    const { handleChange, value } = useField(
      name,
      { required: required.value },
      {
        type: 'radio',
        checkedValue: truthyValue,
        uncheckedValue: falsyValue,
        initialValue: initialValue.value,
      }
    );
    return {
      ...toRefs(state),
      handleChange,
      value,
    };
  },
};
</script>
<style scoped>
.radio-icon-container {
  @apply absolute
  shadow-inner
  bg-[#f0f0f0]
  top-1/4
  left-1/4
  w-[50%]
  h-[50%]
  transition
  rounded-full;
}

.radio-icon-wrapper {
  @apply relative w-12 h-12 min-w-12 min-h-12 select-none rounded-full;
}

.radio-wrapper {
  @apply inline-flex items-center cursor-pointer select-none;
}

.radio-wrapper.disabled {
  @apply cursor-not-allowed;
}

.radio-wrapper.read-only {
  @apply pointer-events-none;
}
</style>
