import Echo from 'laravel-echo';
import Pusher from 'pusher-js';

export default () => {
  window.Pusher = Pusher;

  const token = localStorage.getItem('token');

  window.Echo = new Echo({
    broadcaster: 'pusher',
    key: `${import.meta.env.VITE_MIX_PUSHER_KEY}`,
    wsHost: `${import.meta.env.VITE_SOCKET_DOMAIN}`,
    wsPort: `${import.meta.env.VITE_SOCKET_PORT}`,
    wssPort: `${import.meta.env.VITE_SOCKET_PORT}`,
    forceTLS: import.meta.env.VITE_MIX_PUSHER_APP_FORCE_TLS,
    encrypted: import.meta.env.VITE_MIX_PUSHER_ENCRYPTED,
    enabledTransports: ['ws', 'wss'],
    disableStats: true,
    auth: {
      headers: {
        authorization: `Bearer ${token}`,
        accept: 'application/json',
      },
    },
    authEndpoint: `${import.meta.env.VITE_API}/broadcasting/auth`,
  });
};
