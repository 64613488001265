<script setup>
import { computed, ref, onMounted, onUnmounted } from 'vue';
import { useStore } from 'vuex';
import { BaseApplicantBio, BaseButton, BaseIcon } from '@/components/Base';
import ApplicantPagination from '@/components/Elements/Applicant/ApplicantPagination.vue';
import ApplicationForm from '@/components/Elements/Application/ApplicationForm.vue';
import SearchDialog from '@/components/Elements/Application/SearchDialog.vue';
import { PDialog } from '@/components/ProxifyUI';
import api from '@/api';
import AppLoader from '@/components/App/AppLoader.vue';

const store = useStore();
const currentRoute = computed(() => store.state.appTraffic.currentRoute);
const pagination = computed(() => store.state.appTraffic.pagination);
const notificationCount = computed(
  () => store.getters['notifications/unreadCount']
);
const todosCount = computed(() => store.getters['todos/expeditedTodosCount']);
const isLoading = computed(() => store.getters['appTraffic/isLoading']);

const isSearchDialogActive = ref(false);
const uploadProgress = ref(0);
const formDialog = ref(null);

const handleSubmitApplicationForm = async (form) => {
  const { data } = await api.applications.postApplication(form);
  const { id } = data.response;
  if (form.attachments) {
    const formData = new FormData();
    Object.keys(form.attachments).forEach((key) => {
      if (form.attachments[key]) {
        if (
          Object.prototype.toString.call(form.attachments[key]).slice(8, -1) ===
          'File'
        ) {
          formData.append(key, form.attachments[key]);
        }
      }
    });
    await api.applications.updateAttachments(formData, {
      id,
      onUploadProgress,
    });
  }

  store.commit('application/setCounterNeedUpdate', data.response.stage.id);
  store.commit('application/moveApplication', {
    application: {
      ...data.response,
      uuid: data.response.applicant?.uuid ?? `app-${data.response.id}`,
    },
    stageTo: data.response.stage.id,
  });
  store.commit('application/addToNewApplications', {
    stageId: data.response.stage.id,
    application: data.response,
  });
  store.commit('ui/addSnackbarMessage', {
    title: 'New applicant was successfully created!',
    type: 'success',
    displayDuration: 5000,
  });
};
const onUploadProgress = ({ loaded, total }) => {
  uploadProgress.value = Math.ceil((loaded / total) * 100);
};
const onClickAddApplication = () => {
  formDialog.value?.reveal();
};
const handleKeydown = (event) => {
  if ((event.metaKey || event.ctrlKey) && event.key.toLowerCase() === 'k') {
    event.preventDefault();
    isSearchDialogActive.value = !isSearchDialogActive.value;
  }
};
onMounted(() => {
  window.addEventListener('keydown', handleKeydown);
});
onUnmounted(() => {
  window.removeEventListener('keydown', handleKeydown);
});
</script>
<template>
  <div
    class="h-[42px] flex sm:max-sm:flex-1 min-w-[0px] px-8 items-center justify-between"
  >
    <div class="flex gap-2 sm:max-sm:flex-1 min-w-[0px] items-center">
      <q-breadcrumbs
        class="sm:max-sm:flex-1 min-w-[80px] text-proxify-gray-600 text-body-sm font-medium"
        active-color="#475467"
      >
        <template #separator>
          <BaseIcon
            name="chevron-right"
            size="16px"
            class="text-proxify-gray-300"
          />
        </template>
        <q-breadcrumbs-el
          v-for="(item, index) in currentRoute"
          :key="index"
          class="truncate gap-2"
          :class="{
            'max-w-[150px]': currentRoute.length > 1,
          }"
          :to="item.path"
        >
          <BaseIcon
            v-if="item.icon"
            :name="item.icon"
            size="20px"
            class="text-proxify-gray-500"
          />
          <AppLoader v-if="isLoading && index && currentRoute?.[1]?.path" />
          <div
            v-else
            class="md:block truncate"
            :class="{
              'tw-hidden': !index && currentRoute?.[1]?.path,
            }"
          >
            {{ item.name }}
          </div>
        </q-breadcrumbs-el>
      </q-breadcrumbs>
      <ApplicantPagination
        v-if="pagination && currentRoute?.[1]?.path"
        :adjacent-applications="pagination"
        :is-loading="isLoading"
      />
    </div>

    <div class="flex gap-4 pl-4 items-center">
      <BaseButton
        class="icon-secondary-gray py-2.5 text-body-sm"
        outlined
        rounded
        icon-prepend="plus"
        icon-size="20px"
        @click="onClickAddApplication"
      >
        Add applicant
      </BaseButton>
      <BaseButton
        icon-prepend="search-lg"
        icon-size="20px"
        round
        class="icon-secondary-gray"
        outlined
        data-testid="search-button"
        @click="isSearchDialogActive = true"
      />
      <PDialog
        v-model="isSearchDialogActive"
        position="standard"
        data-testid="search-dialog"
      >
        <SearchDialog />
      </PDialog>
      <BaseButton
        icon-prepend="bell01"
        icon-size="20px"
        round
        class="icon-secondary-gray"
        outlined
        :badge-label="notificationCount"
        @click="store.commit('settings/setNotificationBarStatus')"
      ></BaseButton>
      <BaseButton
        icon-prepend="check-done01"
        icon-size="20px"
        round
        class="icon-secondary-gray"
        outlined
        :badge-label="todosCount"
        @click="store.commit('todos/setTodosMenuStatus')"
      ></BaseButton>
    </div>
    <ApplicationForm
      ref="formDialog"
      :upload-progress="uploadProgress"
      :on-submit="handleSubmitApplicationForm"
    ></ApplicationForm>
  </div>
</template>

<style>
.q-breadcrumbs--last {
  @apply pr-2
  min-w-[40px];
}
</style>
