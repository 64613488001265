<template>
  <div
    class="bg-white h-[72px] flex justify-between items-center"
    @click="$router.push(`/applications/${application.id}`)"
  >
    <div class="py-3 px-6 w-2/12 min-w-[21.5%]">
      <BaseApplicantBio
        :avatar-url="application?.avatar"
        avatar-background-color="#f4f5f7"
        :name="`${application?.first_name} ${application?.last_name}`"
        name-class="text-body-md font-medium text-proxify-gray-700 truncate w-full"
        supporting-text-class="text-body-sm font-normal text-proxify-gray-600 truncate w-full"
        text-wrapper-class="flex flex-col justify-center items-start overflow-hidden gap-[4px]"
        dense
        enable-avatar-letters
        :fast-tracked="application?.fast_tracked"
      >
        <template #supporting-text>
          <BaseRating
            v-if="applicantCardSettings?.showOnCards.items.rating.value"
            :model-value="application?.average_rating"
            read-only
            :name="application?.uuid"
            data-testid="rating"
          />
        </template>
      </BaseApplicantBio>
    </div>
    <div
      class="w-2/12 min-w-[16.66%] py-2.5 px-6 flex flex-col justify-center gap-2"
    >
      <BaseButtonDropdown
        :options="menuOptions.stage"
        map-options
        :model-value="currentStage"
        @click.stop
        @update:model-value="
          (value) =>
            $emit('change-stage', {
              previousStageId: currentStage.id,
              nextStageId: value.id,
            })
        "
      >
        <template #activator>
          <BaseBadge
            color="transparent"
            class="!text-proxify-primary-600 !p-0 cursor-pointer font-body-sm h-[unset]"
          >
            {{ currentStage?.name }}
            <template #append>
              <BaseIcon
                name="chevron-down"
                color="primary"
                size="20px"
              />
            </template>
          </BaseBadge>
        </template>
      </BaseButtonDropdown>
      <div v-if="getInfoContent(application)?.show">
        <BaseBadge
          :color="getInfoContent(application)?.color"
          class="!mx-0 pl-[10px] pr-[6px]"
          size="sm"
        >
          <span class="text-body-sm font-normal">
            {{ getInfoContent(application)?.label }}
          </span>
          <template #append>
            <BaseIcon
              name="info-circle"
              :class="`text-proxify-${getInfoContent(application)?.color}-500`"
              size="12px"
            />
            <BaseTooltip :offset="[0, 6]">
              <div class="relative flex flex-col gap-[4px]">
                <div class="text-body-xs font-semibold">
                  {{ getInfoContent(application)?.contentTitle }}
                </div>
                <p class="text-body-xs font-medium">
                  <span
                    v-safe-html="getInfoContent(application)?.contentBody"
                  ></span>
                </p>
              </div>
            </BaseTooltip>
          </template>
        </BaseBadge>
      </div>
    </div>
    <div
      v-if="applicantCardSettings?.showOnCards.items.skills.value"
      class="w-2/12 min-w-[21.5%] flex items-center px-4 gap-[4px]"
    >
      <ApplicantSkillBadge
        v-for="item in separatedSkills.mainSkills"
        :key="item.skill.id"
        :skill="item"
        :show-proficiency="false"
        :show-experience="false"
        class="max-w-[168px] !px-2.5 !py-0.5"
        label-class="!text-body-xs"
      />
      <div v-if="separatedSkills.secondarySkills.length">
        <BaseBadge
          badge-class="max-w-[168px] !px-2.5 !py-0.5 !text-body-xs cursor-default"
        >
          <template #append>
            <BaseBadge
              v-if="highDemandSecondarySkillExists"
              size="sm"
              icon="chevron-up-double"
              color="success"
              icon-position="left"
              class="capitalize !text-body-sm !px-0 bg-transparent"
            />
            <BaseIcon
              name="plus"
              class="-mx-1.5"
              :class="{
                'text-proxify-success-600': highDemandSecondarySkillExists,
                'pl-1': !highDemandSecondarySkillExists,
              }"
            />
            <span
              :class="{
                'text-proxify-success-600': highDemandSecondarySkillExists,
              }"
            >
              {{ separatedSkills.secondarySkills.length }}
            </span>
            <BaseTooltip
              :offset="[0, 6]"
              tooltip-class="!max-h-[unset]"
            >
              <div class="font-semibold my-1">
                Secondary Skills ({{ separatedSkills.secondarySkills.length }})
              </div>
              <div class="flex flex-col gap-[8px]">
                <ApplicantSkillBadge
                  v-for="item in separatedSkills.secondarySkills"
                  :key="item.skill.id"
                  :skill="item"
                />
              </div>
            </BaseTooltip>
          </template>
        </BaseBadge>
      </div>
    </div>
    <div
      v-if="applicantCardSettings?.showOnCards.items.recruiter.value"
      class="w-2/12 min-w-[21.5%] flex items-center px-4"
    >
      <BaseButtonDropdown
        :options="menuOptions.recruiter"
        map-options
        :model-value="currentRecruiter"
        @update:model-value="(value) => $emit('change-recruiter', value)"
        @click.stop
      >
        <template #activator>
          <BaseBadge
            color="transparent"
            class="!text-proxify-primary-600 !p-0 cursor-pointer font-body-sm h-[unset]"
            @click.stop
          >
            <template #prepend>
              <PAvatar
                size="20px"
                class="m-0"
              >
                <img
                  v-if="currentRecruiter?.avatar_url && !forcePlaceholder"
                  :src="currentRecruiter?.avatar_url"
                  :alt="currentRecruiter?.name"
                  @error="forcePlaceholder = true"
                />
                <BaseIcon
                  v-else
                  name="user01"
                />
              </PAvatar>
            </template>
            {{ currentRecruiter?.name ?? 'Select recruiter' }}
            <template #append>
              <BaseIcon
                name="chevron-down"
                color="primary"
                size="20px"
              />
            </template>
          </BaseBadge>
        </template>
      </BaseButtonDropdown>
    </div>
    <div class="w-1/12 min-w-[11%] flex items-center justify-center gap-2">
      <div v-if="applicantCardSettings?.showOnCards.items.activity.value">
        <BaseBadge
          class="!p-0 !text-body-xs !font-semibold"
          dense
          :icon="application.auto_transition ? 'magic-wand01' : 'activity'"
          color="transparent"
        >
          {{ getTimeDifference(application.last_activity) }}
          <BaseTooltip v-if="application.auto_transition">
            <div class="relative flex flex-col gap-[4px]">
              <div class="text-body-xs font-semibold">Auto transition</div>
              <p class="text-body-xs font-medium">
                This candidate was automatically moved from
                {{ fromStage?.name }}
                to {{ currentStage?.name }}
              </p>
            </div>
          </BaseTooltip>
        </BaseBadge>
      </div>
      <div
        v-if="
          applicantCardSettings?.showOnCards.items.activity.value &&
          application.applied_at
        "
      >
        <BaseBadge
          class="!p-0 !text-body-xs !font-semibold"
          dense
          icon="user01"
          color="transparent"
          data-testid="applied-at-badge"
        >
          {{ getTimeDifference(application.applied_at) }}
        </BaseBadge>
      </div>
      <BaseButton
        v-if="applicantCardSettings?.showOnCards.items.action.value"
        icon-prepend="share07"
        icon-size="20px"
        class="p-2.5 icon-tertiary-gray border-none"
        @click.stop="$emit('open-share-link-dialog', application.id)"
      />
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex';
import { answers } from '@/constants/filters';
import { computed, ref } from 'vue';
import { applicationUtils, dateTimeUtils } from '@/utils';
import moment from 'moment';
import ApplicantSkillBadge from '@/components/Elements/Applicant/ApplicantSkillBadge.vue';
import { PAvatar } from '@/components/ProxifyUI';
import {
  BaseApplicantBio,
  BaseBadge,
  BaseButton,
  BaseIcon,
  BaseButtonDropdown,
  BaseTooltip,
  BaseRating,
} from '@/components/Base';

export default {
  components: {
    BaseRating,
    ApplicantSkillBadge,
    PAvatar,
    BaseApplicantBio,
    BaseBadge,
    BaseButton,
    BaseIcon,
    BaseButtonDropdown,
    BaseTooltip,
  },
  props: {
    application: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
    index: {
      type: Number,
      required: false,
      default() {
        return undefined;
      },
    },
    menuOptions: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
    onClickTrigger: {
      type: Function,
      required: false,
      default() {
        return undefined;
      },
    },
  },
  emits: ['change-recruiter', 'change-stage', 'open-share-link-dialog'],
  setup(props) {
    const stageTrigger = ref(null);
    const recruiterTrigger = ref(null);
    const sourcerTrigger = ref(null);
    const store = useStore();

    const forcePlaceholder = ref(false);

    const applicantCardSettings = computed(
      () => store.getters['settings/applicantCardSettings']
    );
    const stages = computed(() => store.state.applicant.stages);
    const currentStage = computed(() =>
      stages.value.find(({ id }) => id === props.application.stage_id)
    );
    const fromStage = computed(() =>
      stages.value.find(
        ({ id }) => id === props.application.auto_transition?.from_stage_id
      )
    );

    const currentRecruiter = computed(() =>
      (props.menuOptions?.recruiter ?? []).find(
        ({ id }) => id === props.application.recruiter_id
      )
    );

    const parseSkills = (skill) => {
      const skillName = skill.skill.name;
      const yearsExperience = skill.years;
      const proficiencyLevel =
        answers.proficiency_level[skill.proficiency_level - 1];

      return {
        title: `${skillName} — ${yearsExperience}y`,
        subtitle: `${proficiencyLevel}`,
      };
    };

    const separatedSkills = computed(() => {
      return applicationUtils.separateSkills(
        props.application.application_skills
      );
    });

    const highDemandSecondarySkillExists = computed(() => {
      return applicationUtils
        .separateSkills(props.application.application_skills)
        .secondarySkills.some(
          (skill) =>
            skill.years >= 4 &&
            skill.supply_demand_ratio.label === 'High demand'
        );
    });

    const generateRejectionContent = (rejection) => {
      if (!rejection) return '';

      const reasons = (rejection.reasons ?? [])
        .filter((reason) => reason.name !== 'Other')
        .sort((a, b) => b.is_main - a.is_main)
        .map(
          (reason) => `<strong>${reason.name}</strong>: ${reason.description}`
        );

      const details = rejection.details
        ? `<strong>${rejection.reasons?.some((reason) => reason.name === 'Other') ? 'Other' : 'Detail Added'}:</strong> ${rejection.details}`
        : '';

      return [...reasons, details].join('<br>');
    };

    const getInfoContent = ({
      is_hired,
      hired_at,
      is_rejected,
      rejection,
      has_not_scheduled_meeting,
    }) => {
      const context = [
        {
          show: Boolean(is_hired),
          color: 'success',
          label: 'Joined Network',
          contentBody: `Member of the community since \n ${moment(
            hired_at
          ).format('DD MMMM YYYY')}`,
        },
        {
          show: Boolean(is_rejected),
          color: 'error',
          label: rejection?.type,
          contentBody: generateRejectionContent(rejection),
        },
        {
          show: Boolean(has_not_scheduled_meeting),
          color: 'warning',
          label: 'Not scheduled',
          contentBody:
            "This candidate hasn't scheduled the meeting for the current stage yet. You might need to resend an invite.",
        },
      ];
      return context.find(({ show }) => show);
    };

    return {
      applicantCardSettings,
      stageTrigger,
      recruiterTrigger,
      sourcerTrigger,
      calculateRelativeClass: dateTimeUtils.calculateRelativeClass,
      getTimeDifference: dateTimeUtils.getTimeDifference,
      moment,
      fromStage,
      currentStage,
      parseSkills,
      getInfoContent,
      separatedSkills,
      highDemandSecondarySkillExists,
      currentRecruiter,
      forcePlaceholder,
    };
  },
};
</script>
