<script setup>
import { computed, reactive, ref, toRefs, watch } from 'vue';
import { useStore } from 'vuex';
import { useForm } from 'vee-validate';

import { useClipboard } from '@vueuse/core';
import {
  BaseButton,
  BaseConfirmationCard,
  BaseInputDropdown,
  BaseInputField,
  BaseInputFile,
  BaseToggle,
  BaseLabelScheduler,
} from '@/components/Base';
import { PDialog } from '@/components/ProxifyUI';
import ApplicantCodilityDialog from '@/components/Elements/Applicant/ApplicantCodilityDialog.vue';
import EmailPreviewCard from '@/components/Elements/Scaffold/EmailPreviewCard.vue';

const props = defineProps({
  onCancel: {
    type: Function,
    required: true,
  },
  onConfirm: {
    type: Function,
    required: true,
  },
  isLoading: {
    type: Boolean,
    required: false,
  },
  actions: {
    type: Array,
    required: true,
  },
  applicationId: {
    type: [Number, String],
    required: false,
  },
});

const store = useStore();
const codilityActionsPopup = ref(false);
const state = reactive({
  sendAction: undefined,
  file: null,
  selectedAction: {},
  isMenuActive: false,
  menuOptions: [],
  toggleModel: false,
  showPreview: false,
});

const { sendAction, file, selectedAction, toggleModel, showPreview } =
  toRefs(state);

const { copy, copied } = useClipboard();

// Computed validation rules
const schema = computed(() => {
  return {
    'Email template': 'required',
    Attachment: {
      required:
        selectedAction?.value?.required_attachment_type !== 'offer_pdf' &&
        toggleModel.value &&
        Boolean(selectedAction?.value?.required_attachment_type),
    },
    Link: {
      required:
        toggleModel.value &&
        Boolean(selectedAction?.value?.required_link_type === 'codility_test'),
    },
  };
});

const { handleSubmit } = useForm({
  validationSchema: schema,
});

// Generate Codility Dialog Functions
const handleOpenCodilityDialog = () => {
  codilityActionsPopup.value = true;
};
const onCodilityTestGenerated = (link) => {
  selectedAction.value = {
    ...selectedAction.value,
    link,
  };
  codilityActionsPopup.value = false;
};

// Stage Action Functions
const performStageActions = () => {
  if (!toggleModel.value) {
    props.onCancel();
    return;
  }
  try {
    if (selectedAction?.value?.id) {
      props.onConfirm(
        file.value,
        selectedAction?.value,
        sendAction.value?.value
      );
    } else {
      store.commit('ui/addSnackbarMessage', {
        title: 'You have to select an email template',
        type: 'error',
      });
    }
  } catch (error) {
    console.log(error);
  } finally {
    props.onCancel();
  }
};

const handleInvalidSubmit = ({ errors }) => {
  const invalidQuestions = Object.keys(errors);
  const firstInvalidQuestionElement = document.querySelector(
    `[data-name='${invalidQuestions[0]}']`
  );
  if (firstInvalidQuestionElement) {
    firstInvalidQuestionElement.scrollIntoView();
  }
};

watch(
  () => props.actions,
  (newValue) => {
    if (newValue.length) {
      selectedAction.value = newValue[0];
    }
  },
  {
    immediate: true,
    deep: true,
  }
);

watch(
  () => copied.value,
  (newValue) => {
    if (newValue) {
      store.commit('ui/addSnackbarMessage', {
        title: 'Copied to clipboard',
        type: 'success',
      });
    }
  },
  {
    immediate: true,
    deep: true,
  }
);
</script>
<template>
  <BaseConfirmationCard
    :on-confirm="handleSubmit(performStageActions, handleInvalidSubmit)"
    :on-close="onCancel"
    :cancel-button-text="null"
    :is-loading="isLoading"
    paddingless
  >
    <template #title>Send automatic email?</template>
    <div class="flex flex-col gap-4 px-6">
      <div
        class="font-medium text-body-sm font-proxify-gray-700 flex flex-col gap-1.5"
      >
        <span>E-mail template</span>
        <BaseInputDropdown
          v-model="selectedAction"
          name="Email template"
          :options="actions"
          map-options
          type="search"
        >
          <template #after>
            <BaseButton
              class="icon-tertiary-gray border-none p-2.5 trailing-button"
              icon-prepend="eye"
              @click="showPreview = true"
            />
          </template>
        </BaseInputDropdown>
      </div>
      <div
        v-if="selectedAction?.link"
        class="font-medium text-body-sm font-proxify-gray-700 flex flex-col gap-1.5"
      >
        <span>
          {{
            selectedAction?.required_link_type === 'codility_test'
              ? 'Codility Test Link'
              : 'Schedule event link'
          }}
        </span>
        <BaseInputField
          v-model="selectedAction.link"
          name="Link"
          class="action-link"
        >
          <template #after>
            <BaseButton
              class="icon-tertiary-gray border-none p-2 trailing-button"
              icon-prepend="copy01"
              :disable="!selectedAction?.link.trim()"
              @click="copy(selectedAction?.link)"
            />
          </template>
        </BaseInputField>
        <BaseButton
          v-if="selectedAction?.required_link_type === 'codility_test'"
          class="icon-link-color text-proxify-primary-700 w-fit px-0"
          @click="handleOpenCodilityDialog"
        >
          Generate new link
        </BaseButton>
      </div>
      <div v-if="selectedAction?.required_attachment_type">
        <div
          class="font-medium text-body-sm font-proxify-gray-700 flex flex-col gap-1.5"
        >
          File
        </div>
        <BaseInputFile
          name="Attachment"
          :accept="selectedAction?.attachment_mime_types"
          @handle-files-changed="(event) => (file = event)"
        />
      </div>
      <BaseToggle v-model="toggleModel">
        <div class="text-body-sm">
          <div class="font-medium text-proxify-gray-700">
            Send e-mail to the applicant
          </div>
          <div class="font-normal text-proxify-gray-600">
            You will see this e-mail under e-mails tab.
          </div>
        </div>
      </BaseToggle>
      <BaseLabelScheduler
        v-if="toggleModel"
        v-model="sendAction"
      />
    </div>
  </BaseConfirmationCard>
  <!-- Email Preview -->
  <PDialog
    v-model="showPreview"
    position="standard"
  >
    <EmailPreviewCard
      :title="selectedAction?.name"
      :template="selectedAction?.defaultEmailPreview"
      :on-close="() => (showPreview = false)"
    />
  </PDialog>
  <!-- Codility Generate -->
  <Transition>
    <ApplicantCodilityDialog
      v-if="codilityActionsPopup"
      class="ml-8"
      :on-generate-test="onCodilityTestGenerated"
      :application-id="applicationId"
      :on-close="() => (codilityActionsPopup = false)"
    />
  </Transition>
</template>
<style lang="scss" scoped>
.action-link {
  .q-field__inner {
    border-radius: 8px !important;
  }

  .q-field__after {
    padding-left: 5px;
  }
}

.codility-dialog {
  .q-dialog__inner {
    right: 32px !important;
  }
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.4s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
