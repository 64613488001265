<script setup>
import { computed, ref, watch, watchEffect } from 'vue';
import { useStore } from 'vuex';
import {
  computedEager,
  useIntervalFn,
  useBroadcastChannel,
} from '@vueuse/core';
import {
  BaseBadge,
  BaseButtonToggle,
  BaseIcon,
  BaseEmptyState,
} from '@/components/Base';
import { PCircularProgress, PDialog } from '@/components/ProxifyUI';
import ApplicantActivityInterviewScorecardLayout from '@/components/Elements/Applicant/ApplicantActivityInterviewScorecardLayout.vue';
import RescheduleCard from '@/components/Elements/Applicant/ApplicantActivityInterviewRescheduleCard.vue';
import HomeInterviewRow from './HomeInterviewRow.vue';

import moment from 'moment';
import api from '@/api';
import { dateTimeUtils } from '@/utils';

defineProps({
  containerHeight: {
    type: String,
    required: false,
    default: undefined,
  },
});

const store = useStore();
const interviewers = computed(() => store.state.application.interviewers);
const recruiters = computed(() => store.state.applicant.recruiters);
const currentUser = computed(() => store.state.auth.user);
const isLogged = computed(() => store.getters['auth/isLogged']);
const interviewsNeedUpdate = computed(
  () => store.state.applicant.interviewsNeedUpdate
);

const isScorecardDialogOpen = ref(false);
const isRescheduleDialogOpen = ref(false);
const rescheduleReason = ref('');
const selectedInterview = ref(null);
const date = ref('');
const interviews = ref([]);
const isLoading = ref(false);

const { data: channelMessage } = useBroadcastChannel({
  name: 'data-needs-update',
});

const title = computedEager(() => {
  const interviewerRoles = (currentUser.value?.roles ?? []).filter(({ name }) =>
    ['talent-acquisition-specialist', 'interviewer'].includes(name)
  );
  if (interviewerRoles.length > 1) {
    return 'All interviews';
  } else if (!interviewerRoles.length) {
    return 'Interviews';
  } else if (interviewerRoles[0].name === 'talent-acquisition-specialist') {
    return 'Screening interviews';
  }
  return 'Technical interviews';
});

const paginationOptions = computedEager(() => [
  {
    label: 'Yesterday',
    value: moment().subtract(1, 'days').format('YYYY-MM-DD'),
    slot: 'yesterday',
  },
  {
    label: `Today, ${moment().format('MMM DD')}`,
    value: '',
    slot: 'today',
  },
  {
    label: 'Tomorrow',
    value: moment().add(1, 'days').format('YYYY-MM-DD'),
    slot: 'tomorrow',
  },
]);
const closeScorecardDialog = () => (isScorecardDialogOpen.value = false);
const closeRescheduleDialog = () => (isRescheduleDialogOpen.value = false);
const openScorecardDialog = (interview) => {
  selectedInterview.value = interview;
  if (!interview.karatInterview) {
    isScorecardDialogOpen.value = true;
  }
};
const openRescheduleDialog = ({ interview, reason }) => {
  rescheduleReason.value = '';
  selectedInterview.value = interview;
  if (reason) {
    rescheduleReason.value = reason;
  }
  isRescheduleDialogOpen.value = true;
};

store.dispatch('application/getInterviewers');
store.dispatch('applicant/getRecruiters');

const sortInterviewsByStartTime = (interviews) => {
  return interviews.sort((a, b) => {
    const startTimeA = dateTimeUtils.formatDateTimeString(
      a.calendarEvent?.date,
      a.calendarEvent?.start_at,
      a.calendarEvent?.timezone,
      'X'
    );

    const startTimeB = dateTimeUtils.formatDateTimeString(
      b.calendarEvent?.date,
      b.calendarEvent?.start_at,
      b.calendarEvent?.timezone,
      'X'
    );

    return startTimeA - startTimeB; // For ascending order
  });
};
const getInterviews = async (date) => {
  if (isLogged.value) {
    try {
      isLoading.value = true;
      const { data: { interviews: response } = {} } =
        await api.applications.interviews.getUserInterviews({
          params: { date: date || null },
        });
      interviews.value = sortInterviewsByStartTime(response);
    } catch (error) {
      console.error(error);
    } finally {
      isLoading.value = false;
    }
  }
};
watchEffect(() => {
  getInterviews(date.value);
});
watch(
  () => [channelMessage.value, interviewsNeedUpdate.value],
  ([newChannelMessage, newTimestamp], oldValue) => {
    if (
      newChannelMessage?.data === 'interviews' ||
      (newTimestamp && newTimestamp !== oldValue?.[1])
    ) {
      getInterviews(date.value);
    }
  },
  { deep: true }
);

useIntervalFn(
  () => {
    getInterviews(date.value);
  },
  1000 * 60 * 5
);
</script>

<template>
  <div class="interviews-table">
    <div class="interviews-table__header">
      <div class="interviews-table__header-title">
        <div>
          {{ title }}
        </div>
        <BaseBadge v-if="!isLoading">
          {{
            interviews.length && !date
              ? `You have ${interviews.length} interview${
                  interviews.length !== 1 ? 's' : ''
                } today`
              : interviews.length
          }}
        </BaseBadge>
      </div>
      <div class="interviews-table__header-actions">
        <BaseButtonToggle
          v-model="date"
          :options="paginationOptions"
        >
          <template #today>
            <BaseIcon
              name="dot"
              size="8px"
              class="proxify-success-500"
            />
          </template>
        </BaseButtonToggle>
      </div>
    </div>
    <div
      class="interviews-table__body"
      :class="{ 'overflow-hidden': isLoading }"
    >
      <div v-if="isLoading">
        <PCircularProgress
          rounded
          indeterminate
          color="primary"
          class="w-full align-center"
        ></PCircularProgress>
      </div>
      <template v-else>
        <BaseEmptyState
          v-if="!interviews.length"
          title="No interviews"
          description="Your interviews will appear here."
          icon="calendar-minus01"
          class="empty-state"
        />
        <HomeInterviewRow
          v-for="interview in interviews"
          :key="interview.id"
          :interview="interview"
          :open-reschedule-dialog="openRescheduleDialog"
          :open-scorecard-dialog="openScorecardDialog"
        ></HomeInterviewRow>
      </template>
    </div>
  </div>
  <PDialog
    v-model="isScorecardDialogOpen"
    full-height
    maximized
    half-width
    persistent
  >
    <ApplicantActivityInterviewScorecardLayout
      ref="scoreCard"
      :application-data="selectedInterview.application"
      :codility-score="selectedInterview?.application?.grade?.codility_score"
      :interview="selectedInterview"
      @open-reschedule-dialog="openRescheduleDialog"
      @close="closeScorecardDialog"
    />
  </PDialog>

  <PDialog
    v-model="isRescheduleDialogOpen"
    position="standard"
    persistent
  >
    <RescheduleCard
      :interview-id="selectedInterview.id"
      :interview-name="selectedInterview.name"
      :interviewers="interviewers"
      :initial-interviewer="selectedInterview.interviewer"
      :recruiters="recruiters"
      :close-reschedule-dialog="closeRescheduleDialog"
      :is-no-show-flow="rescheduleReason === 'no-show'"
      :on-reschedule="() => getInterviews(date)"
    />
  </PDialog>
</template>
<style lang="scss" scoped>
.interviews {
  &-table {
    @apply flex
      flex-col
      rounded-xl
      border
      border-proxify-gray-200
      bg-white
      shadow-sm
      min-h-80
      w-full;

    max-height: v-bind(containerHeight);

    &__header {
      @apply flex
          flex-row
          items-center
          justify-between
          px-6
          py-5
          border-b
          border-proxify-gray-200;

      &-title {
        @apply flex
        flex-row
        items-center
        gap-2
        
        text-body-lg
        font-semibold
        text-proxify-gray-900;
      }
    }

    &__body {
      @apply flex
      flex-col
      grow
      gap-3
      px-4
      py-3
      overflow-y-scroll
      proxify-scrollbar;

      &:has(.empty-state) {
        @apply items-center justify-center;
      }
    }
  }
}
</style>
