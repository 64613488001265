<template>
  <div
    ref="datePicker"
    :style="computedPositions"
    class="date-picker"
    :class="{
      'date-picker--collapsed': !Boolean(activeMenuType),
      'date-picker--lite': lite,
      'flex max-w-[34rem]': timePicker,
    }"
  >
    <div
      v-if="!lite"
      class="date-picker__header"
    >
      <div class="date-picker__header--title">Select a date</div>
    </div>
    <div class="date-picker__body">
      <div class="date-picker__body--column">
        <div class="date-picker__body--actions">
          <div class="month">
            <span
              class="mr-0.5 cursor-pointer hover:underline"
              @click="activeMenuType = 'month'"
            >
              {{ selectedMonth }}
            </span>
            <span
              class="ml-0.5 cursor-pointer hover:underline"
              @click="activeMenuType = 'year'"
            >
              {{ selectedYear }}
            </span>
          </div>
          <div class="arrows">
            <div class="arrows-group">
              <IconBase
                class="text-white mt-0.5 hover:cursor-pointer"
                background-color="#5258FB"
                height="1.3"
                width="1.3"
                @click.prevent="prevMonth"
              >
                <IconArrowLeft />
              </IconBase>
              <IconBase
                class="text-white ml-2 mt-0.5 hover:cursor-pointer"
                background-color="#5258FB"
                height="1.3"
                width="1.3"
                @click.prevent="nextMonth"
              >
                <IconArrowRight />
              </IconBase>
            </div>
          </div>
          <div
            class="clear"
            :class="{
              disabled: !selectedDate,
            }"
            @click="onClear"
          >
            Clear
          </div>
        </div>
        <div class="date-picker__body--days">
          <div
            v-for="(day, index) in weekdays"
            :key="`day-name-${index + 1}`"
            class="day-name"
            :title="day"
          >
            {{ day }}
          </div>

          <div
            v-for="(day, index) in days"
            :key="index"
            class="day-container"
          >
            <div
              v-if="day"
              class="day"
              :class="{
                selected: isSelectedDay(day),
                today: !selectedDate && day.is_today,
                blocked: day.is_blocked,
              }"
              @click="selectDay(day)"
            >
              {{ day.obj.format('D') }}
            </div>
          </div>
        </div>
      </div>
      <div class="date-picker__body--column">
        <ValueSelector
          hide-trigger
          :is-menu-active="Boolean(activeMenuType)"
          :options="menuOptions"
          :target-element="datePicker"
          :platform-element="datePicker"
          positioning-strategy="relative"
          menu-placement="right-start"
          :menu-offset-x="18"
          :on-close="() => (activeMenuType = null)"
          :on-change="onSelectMonthYear"
        ></ValueSelector>
      </div>
    </div>

    <TimePicker
      v-if="timePicker"
      class="w-32"
      :pick-time="pickTime"
      :selected-date="selectedDate"
    />
  </div>
</template>

<script>
import IconArrowLeft from '@/components/Icons/IconArrowLeft.vue';
import IconArrowRight from '@/components/Icons/IconArrowRight.vue';
import IconBase from '@/components/Icons/IconBase.vue';
import TimePicker from '@/components/Elements/Scaffold/TimePicker.vue';
import ValueSelector from '@/components/Elements/Scaffold/ValueSelector.vue';
import moment from 'moment';
import { ref, toRefs } from 'vue';
import { useComputedPositions } from '@/composables/useComputedPositions';

export default {
  components: {
    IconArrowLeft,
    IconArrowRight,
    IconBase,
    TimePicker,
    ValueSelector,
  },
  props: {
    lite: {
      type: Boolean,
      default: false,
    },
    initialDate: {
      type: [String, Object, Array],
      default: null,
    },
    multiple: {
      type: Boolean,
    },
    preventBlocking: {
      type: Boolean,
    },
    blockedDates: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
    },
    timePicker: {
      type: Boolean,
      default: false,
    },
    pickTime: {
      type: Function,
      required: false,
    },
    targetElement: {
      required: false,
    },
    platformElement: {
      required: false,
    },
    menuPlacement: {
      type: String,
      required: false,
    },
    positioningStrategy: {
      type: String,
      required: false,
    },
    menuOffsetX: {
      type: Number,
      required: false,
    },
    menuOffsetY: {
      type: Number,
      required: false,
    },
    menuPlacementSpacing: {
      type: Number,
      required: false,
    },
    blockPreviousDays: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const datePicker = ref(null);
    const {
      platformElement,
      targetElement,
      menuPlacement,
      menuOffsetX,
      menuOffsetY,
      menuPlacementSpacing,
      positioningStrategy,
    } = toRefs(props);
    const { positions } = useComputedPositions(
      targetElement,
      datePicker,
      menuPlacement,
      {
        platformElement,
        offsetX: menuOffsetX.value,
        offsetY: menuOffsetY.value,
        spacingValue: menuPlacementSpacing.value,
        positioningStrategy: positioningStrategy.value,
      }
    );
    return {
      datePicker,
      computedPositions: positions,
    };
  },
  data() {
    return {
      today: moment(),
      firstDayOfWeek: 1,
      date: null,
      selectedDate: null,
      activeMenuType: null,
      range: [],
    };
  },
  computed: {
    menuOptions() {
      if (this.activeMenuType === 'month') {
        return moment.months().map((month, index) => {
          return {
            value: index,
            name: month,
          };
        });
      }
      return Array.from(Array(12)).map((_, index) => {
        const year = (moment().year() - 6 + index + 1).toString();
        return {
          value: year,
          name: year,
        };
      });
    },
    isToday() {
      return this.selectedDate
        ? this.selectedDate.isSame(moment(), 'day')
        : false;
    },
    weekdays() {
      const weekdays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
      for (let index = 2; index <= this.firstDayOfWeek; index++) {
        const first = weekdays.shift();
        weekdays.push(first);
      }
      return weekdays;
    },
    days() {
      const numberOfDaysFromPreviousMonth =
        (this.startWeekDayOfMonth - this.firstDayOfWeek + 7) % 7;
      const lastMondayOfPreviousMonth =
        this.endDayOfPreviousMonth - numberOfDaysFromPreviousMonth + 1;
      const daysFromPreviousMonth = Array.from(
        Array(this.endDayOfPreviousMonth - lastMondayOfPreviousMonth + 1)
      ).map((_, index) => {
        const day = lastMondayOfPreviousMonth + index;
        return {
          obj: moment(this.date).subtract(1, 'months').date(day),
          is_today: false,
          is_blocked: true,
          is_selected: false,
        };
      });

      const daysInMonth = moment(this.date).daysInMonth();
      const monthArray = [...Array(daysInMonth).keys()];

      const monthFormattedDays = monthArray.map((day) => {
        const date = moment(this.date).date(day + 1);

        const isBlocked =
          this.blockedDates?.includes(date.format('YYYY-MM-DD')) ||
          (this.blockPreviousDays && moment().isAfter(date, 'day'));

        return {
          obj: date,
          is_today: date.isSame(moment(), 'day'),
          is_blocked: isBlocked,
          is_selected: date.isSame(this.selectedDate, 'day'),
        };
      });

      const numberOfDaysFromNextMonth =
        7 - monthFormattedDays[monthFormattedDays.length - 1].obj.day();
      const daysFromNextMonth = Array.from(
        Array(numberOfDaysFromNextMonth)
      ).map((_, index) => {
        const day = index + 1;
        return {
          obj: moment(this.date).add(1, 'months').date(day),
          is_today: false,
          is_blocked: true,
          is_selected: false,
        };
      });

      return [
        ...daysFromPreviousMonth,
        ...monthFormattedDays,
        ...daysFromNextMonth,
      ];
    },
    startWeekDayOfMonth() {
      const first = moment(this.date).startOf('month');

      return first.day();
    },
    endDayOfPreviousMonth() {
      const last = moment(this.date).subtract(1, 'months').endOf('month');

      return last.daysInMonth();
    },
    selectedMonth() {
      return moment(this.date).format('MMMM');
    },
    selectedYear() {
      return moment(this.date).format('YYYY');
    },
  },
  created() {
    this.date = moment(this.initialDate?.[0]);
    this.range = this.initialDate ?? [];
    this.selectedDate = this.date;
  },
  methods: {
    isSelectedDay(day) {
      if (this.multiple && this.range.length > 1) {
        return moment(day.obj).isBetween(
          moment(this.range[0]).startOf('day'),
          moment(this.range[1]).endOf('day'),
          null,
          '[]'
        );
      }

      return day.is_selected;
    },
    prevMonth() {
      this.date = moment(this.date).subtract(1, 'month').startOf('month');
    },
    nextMonth() {
      this.date = moment(this.date).add(1, 'month').startOf('month');
    },
    selectDay(day) {
      if (!day.is_blocked) {
        if (this.multiple) {
          // if the user wants to select a new range, we need to clear the current date range...
          if (this.range.length > 1) {
            this.range = [];
          }

          this.selectedDate = day.obj;
          this.range = [...this.range, day.obj];
          if (this.range.length > 1) {
            this.$emit('pick', this.range);
          }
        } else {
          this.selectedDate = day.obj;
          this.$emit('pick', day);
        }
      }
    },
    onClear() {
      this.selectedDate = moment(this.initialDate?.[0]);
      this.range = [];
      this.$emit('pick', { obj: this.today });
    },
    onSelectMonthYear(value) {
      if (this.activeMenuType === 'month') {
        this.date = moment(this.date).month(value);
      } else {
        this.date = moment(this.date).year(value);
      }
      this.activeMenuType = null;
    },
  },
};
</script>
<style>
.date-picker {
  @apply px-4 py-6 rounded-lg z-20 bg-[#f0f0f0] w-[37.5rem];
}

.date-picker--collapsed {
  @apply max-w-[21rem];
}

.date-picker--lite {
  @apply p-0 bg-transparent;
}

.date-picker__header {
  @apply flex items-center pb-6;
}

.date-picker__header--title {
  @apply font-bold text-xl flex justify-between items-center grow max-w-full;

  flex-basis: 0;
}

.date-picker__body--actions {
  @apply flex px-4 pt-3 pb-0;
}

.date-picker__body--actions .month {
  @apply font-bold text-proxify-primary font-poppins text-lg;
}

.date-picker__body--actions .arrows-group {
  @apply ml-6 flex;
}

.date-picker__body--actions .clear {
  @apply text-proxify-primary cursor-pointer leading-6;

  text-align: right;
  flex: 1;
}

.date-picker__body--actions .clear.disabled {
  @apply text-disabled-normal cursor-not-allowed;
}

.date-picker__body {
  @apply w-[19rem];

  display: flex;
  flex-wrap: wrap;
}

.date-picker__body--column {
  @apply w-[19rem];

  flex-basis: auto;
  flex-grow: 1;
}

.date-picker__body--column:first-child {
  @apply border-t-4 border-proxify-primary bg-white rounded;
}

.date-picker__body--column:nth-child(2) {
  @apply ml-2;
}

@media (width >= 768px) {
  .date-picker__body--column:nth-child(2) {
    @apply pt-3 pr-6 pb-0 pl-10;

    flex: 0 0 41.6666666667%;
  }
}

.date-picker__body--days {
  @apply px-4 pb-4 pt-3 items-center grid grid-cols-7 grid-rows-6 gap-2 justify-center;
}

.date-picker__body--days .day-name {
  @apply text-proxify-black/80 font-poppins font-bold text-2xs;
}

.date-picker__body--days .day-container {
  @apply grid auto-cols-fr grid-flow-col h-8 relative w-full;
}

.date-picker__body--days .day-container .day {
  @apply text-proxify-black content-center rounded-full grid justify-center left-1/2 top-1/2 absolute p-3 h-2 w-2 -translate-x-1/2 -translate-y-1/2 box-content text-lg;
}

.date-picker__body--days .day-container .day:hover {
  @apply text-proxify-primary cursor-pointer;
}

.date-picker__body--days .day-container .day.blocked {
  @apply text-disabled-normal cursor-not-allowed;
}

.date-picker__body--days .day-container .day.selected,
.date-picker__body--days .day-container .day.today {
  @apply text-white bg-proxify-primary rounded-full relative;
}
</style>
