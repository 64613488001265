<template>
  <div
    class="app-snackbar-wrapper drop-shadow-sm"
    :class="color"
    data-testid="snackbar"
  >
    <div class="app-snackbar-content" data-testid="snackbar-content">
      <span v-safe-html="message?.message" class="mr-4"></span>
      <button
        class="ml-2"
        @click="$store.commit('ui/removeSnackbarMessage', message?.id)"
      >
        <IconBase class="text-white" height="0.9" width="0.9">
          <IconCross />
        </IconBase>
      </button>
    </div>
  </div>
</template>

<script>
import IconBase from '@/components/Icons/IconBase.vue';
import IconCross from '@/components/Icons/IconCross.vue';

export default {
  name: 'AppSnackbarItem',
  components: {
    IconBase,
    IconCross,
  },
  props: {
    message: {
      type: Object,
      required: true,
    },
  },
  computed: {
    color() {
      let color = '';
      switch (this.message.type) {
        case 'success':
          color = 'bg-proxify-success';
          break;
        case 'error':
          color = 'bg-ats-red';
          break;
        default:
          color = 'bg-proxify-primary';
      }
      return color;
    },
  },
};
</script>

<style scoped>
.app-snackbar-wrapper {
  @apply mb-2 flex items-center rounded pointer-events-auto text-white;
}

.app-snackbar-content {
  @apply flex justify-between w-full overflow-hidden p-6 text-base;
}
</style>
