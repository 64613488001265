<script setup>
import { ref, watch } from 'vue';
import { useStore } from 'vuex';
import api from '@/api';
import { BaseLoader, BaseInputField, BaseEmptyState } from '@/components/Base';
import SearchListItem from '@/components/Elements/Application/SearchListItem.vue';

const store = useStore();

const searchTerm = ref('');
const results = ref([]);
const showResults = ref(false);
const isLoading = ref(false);
const defaultMessage = ref(true);
const nothingFoundMessage = ref(false);
const controller = ref(null);

const search = async (query) => {
  try {
    // Reset the UI state to the initial loading state
    setDefaultState();
    controller.value = new AbortController();

    // Only proceed if the search term is 3 or more characters
    if (searchTerm.value.length >= 3) {
      isLoading.value = true; // Show the loading indicator

      // Perform the search operation
      const response = await api.applications.search(
        { q: query },
        { signal: controller.value.signal }
      );
      if (response && response.data) {
        isLoading.value = false;
        return handleSearchSuccess(response.data);
      }
    } else {
      // Show a default message when search term is less than 3 characters
      setDefaultState();
    }
  } catch (error) {
    handleError(error);
    isLoading.value = false;
  }
};

// Sets the UI to default state when no or insufficient search term is provided
const setDefaultState = () => {
  if (controller.value) {
    controller.value.abort();
    controller.value = null;
  }
  isLoading.value = false;
  results.value = [];
  showResults.value = false;
  defaultMessage.value = true;
  nothingFoundMessage.value = false;
};

// Handles successful search operations
const handleSearchSuccess = (response) => {
  results.value = response.data;
  defaultMessage.value = false;
  nothingFoundMessage.value = !(response.data && response.data.length);
  showResults.value = Boolean(response.data && response.data.length);
};

// Handles errors during the search operation
const handleError = (error) => {
  if (['ERR_CANCELED', 'ERR_ABORTED'].includes(error.code)) return;
  store.commit('ui/addSnackbarMessage', {
    title:
      error?.response?.message ??
      'An error occurred while searching. Please try again later.',
    type: 'error',
    displayDuration: 3000,
  });
};

watch(searchTerm, (newValue) => {
  search(newValue);
});
</script>
<template>
  <div class="search-card">
    <BaseInputField
      v-model="searchTerm"
      name="Search"
      placeholder="Search name, email, phone number and social links"
      leading-icon="search-lg"
      leading-icon-class="pl-4 text-proxify-gray-500"
      normalize
      autofocus
      input-class="text-body-md placeholder:text-proxify-gray-400"
      class="border-b border-proxify-gray-200 search-input"
      debounce="2000"
      data-testid="search-input"
    />
    <div
      class="flex flex-col gap-2"
      :class="{
        'justify-center items-center flex-1': !showResults,
      }"
    >
      <BaseEmptyState
        v-show="nothingFoundMessage && !isLoading"
        title="Nothing found"
        icon="search-lg"
        data-testid="empty-state-nothing-found"
      >
        <template #description>
          Your search
          <span class="font-semibold text-proxify-gray-900">
            "{{ searchTerm }}"
          </span>
          did not match any applicants.
          <br />
          Please try again.
        </template>
      </BaseEmptyState>
      <BaseEmptyState
        v-show="defaultMessage && !isLoading"
        icon="search-lg"
        icon-class="border-proxify-primary-50 bg-proxify-primary-100 text-proxify-primary-600"
        data-testid="empty-state-default"
      >
        <template #description>
          Type at least
          <span class="font-semibold text-proxify-gray-900">3 letters</span>
          to see matching results
        </template>
      </BaseEmptyState>
      <div
        v-show="isLoading"
        class="flex flex-col justify-center items-center gap-y-4"
      >
        <BaseLoader
          rounded
          indeterminate
          data-testid="search-progress"
        />
        <div class="font-normal text-proxify-gray-600">Searching...</div>
      </div>
    </div>
    <div
      v-show="!isLoading && showResults"
      class="results ats-scrollbar-persistent"
      data-testid="search-state-results-found"
    >
      <SearchListItem
        v-for="(result, index) in results"
        :key="index"
        :item="result"
        :search-term="searchTerm"
      />
    </div>
  </div>
</template>
<style scoped lang="scss">
.search-card {
  @apply flex
  flex-col
  bg-white
  rounded-xl
  w-[640px]
  max-w-[640px]
  min-h-[270px]
  max-h-[80vh];
}

.search-input {
  :deep(.q-field--focused) {
    box-shadow: 0 0 0 4px #f2f4f7;
    border-bottom: 1px solid #eaecf0;
    transition: box-shadow 0.1s ease-in-out;
  }

  :deep(.q-field__control)::before {
    border: none;
  }

  :deep(.q-field__control)::after {
    content: '';
    display: none;
  }
}

.results {
  @apply flex
  flex-col
  overflow-x-hidden
  overflow-y-scroll;
}
</style>
