<script setup>
import { computed, ref, watch } from 'vue';
import {
  PMenu,
  PList,
  PItem,
  PItemSection,
  PSeparator,
} from '@/components/ProxifyUI';
import {
  BaseButton,
  BaseDate,
  BaseIcon,
  BaseInputField,
} from '@/components/Base';
import { useVModel } from '@vueuse/core';
import moment from 'moment';
import TimePicker from '@/components/Elements/Scaffold/TimePicker.vue';
import { useForm } from 'vee-validate';
import { localize } from '@vee-validate/i18n';

const props = defineProps({
  items: {
    type: Array,
    required: true,
  },
  scrollTarget: {
    type: [String, Element],
    required: false,
  },
  target: {
    type: [String, Boolean, Element],
    required: false,
    default: () => true,
  },
  type: {
    type: String,
    required: false,
    default: () => 'list',
  },
  modelValue: {
    type: [String, Array, Object, Number],
    required: false,
  },
  multiple: {
    type: Boolean,
    required: false,
  },
  search: {
    type: Boolean,
    required: false,
    default: () => false,
  },
  dense: {
    type: Boolean,
    required: false,
    default: () => false,
  },
  disablePastDates: {
    type: Boolean,
    default: false,
  },
  menuWidth: {
    type: String,
    required: false,
    default: '240px',
  },
  range: {
    type: Array,
    default() {
      return [];
    },
  },
});

const emit = defineEmits(['update:modelValue']);

const model = useVModel(props, 'modelValue', emit);

const menu = ref(null);
const query = ref('');
const isMenuActive = ref(false);
const inputValues = ref(
  Array.isArray(model.value) &&
    (model.value[0]?.value === 'empty'
      ? []
      : model.value.map((item) => item.value))
);

const inputFields = computed(() => {
  if (props.multiple) {
    return [
      {
        name: 'min',
        title: 'Min',
        placeholder:
          (props.range?.length && props.range[0].toString()) || undefined,
        showErrorMessage: false,
      },
      {
        name: 'max',
        title: 'Max',
        placeholder:
          (props.range?.length && props.range[1].toString()) || undefined,
        showErrorMessage: false,
      },
    ];
  }
  return [
    {
      name: 'inputValue',
      title: isTypeText.value ? 'Enter a city' : 'Enter a value',
      placeholder: (props.range?.length && props.range.join('-')) || undefined,
      showErrorMessage: true,
    },
  ];
});

const filteredItems = computed(() => {
  if (!query.value) {
    return props.items;
  }
  return props.items.filter((item) =>
    item.label.toLowerCase().includes(query.value.toLowerCase())
  );
});

const show = () => {
  menu.value.show();
};

const hide = () => {
  menu.value.hide();
};

defineExpose({
  show,
  hide,
});

const itemComponent = {
  separator: PSeparator,
  item: PItem,
};

const customInputValueErrorMessages = {
  max: 'The city field may not be greater than 25 characters',
  regex:
    'The city field may only contain alphabetic characters as well as dashes and spaces',
};
const customMinMaxErrorMessages = {
  between: 'Values should be between 0:{min}-1:{max}',
  required: 'Field {field} is required',
  max_value: 'Max can’t be equal/bigger than min',
  min_value: 'Min can’t be equal/smaller than max',
};
localize('en', {
  fields: {
    inputValue: customInputValueErrorMessages,
    max: customMinMaxErrorMessages,
    min: customMinMaxErrorMessages,
  },
});

const minMaxValueRule = computed(() => {
  if (Number(inputValues.value[1]) === Number(inputValues.value[0])) {
    return Number(inputValues.value[0]) + 1;
  } else if (Number(inputValues.value[1]) < Number(inputValues.value[0])) {
    return Number(inputValues.value[0]);
  }
  return Number(inputValues.value[1]);
});
const schema = computed(() => {
  return isMenuActive.value
    ? {
        inputValue: {
          required: !props.multiple,
          between: Boolean(props.range.length) && props.range,
          max: isTypeText.value && 25,
          regex: isTypeText.value && /^[a-zA-Z -]+$/,
        },
        min: {
          required: props.multiple,
          between: Boolean(props.range.length) && props.range,
          max_value: Number(inputValues.value[1]) > 0 && minMaxValueRule.value,
        },
        max: {
          required: props.multiple,
          between: Boolean(props.range.length) && props.range,
          min_value: minMaxValueRule.value,
        },
      }
    : {};
});
const { handleSubmit, errors } = useForm({
  validationSchema: schema,
});
const save = () => {
  const value = props.multiple
    ? [
        {
          value: inputValues.value[0],
          name: inputValues.value[0],
        },
        {
          value: inputValues.value[1],
          name: inputValues.value[1],
        },
      ]
    : {
        value: inputValues.value[0],
        name: inputValues.value[0],
      };
  emit('update:modelValue', value);
  hide();
};
const onSubmit = handleSubmit(save, () => {
  const invalidInputs = Object.keys(errors.value);
  document.querySelector(`[data-name='${invalidInputs[0]}'] input`)?.focus();
});

watch(
  () => isMenuActive.value,
  (value) => {
    if (value && Array.isArray(props.modelValue)) {
      inputValues.value = props.modelValue.map((item) => item.value);
    }
  }
);

const dateModelValue = computed(() => {
  if (Array.isArray(model.value)) {
    if (model.value.length > 1) {
      return {
        from: moment(model.value[0].value).format('YYYY/MM/DD'),
        to: moment(model.value[1].value).format('YYYY/MM/DD'),
      };
    }
    return model.value
      .map((item) => moment(item.value).format('YYYY/MM/DD'))
      .toString();
  }
  return model.value ? moment(model.value).format('YYYY/MM/DD') : undefined;
});

const isTypeNumeric = computed(() => props.type === 'numeric');
const isTypeText = computed(() => props.type === 'text');
</script>
<template>
  <PMenu
    ref="menu"
    class="font-medium mt-2 font-inter text-proxify-gray-700 border border-proxify-gray-200 rounded-lg"
    :class="{
      '!shadow-none': !isTypeNumeric,
      'w-80 shadow-lg': isTypeNumeric && props.multiple,
      'w-[168px] shadow-lg': isTypeNumeric && !props.multiple,
      'w-[208px] shadow-lg': isTypeText,
    }"
    square
    :offset="[0, 8]"
    :scroll-target="scrollTarget"
    :target="target"
    :auto-close="false"
    @hide="
      () => {
        query = '';
        isMenuActive = false;
        inputValues = [];
      }
    "
    @show="isMenuActive = true"
  >
    <PList
      v-if="type === 'list'"
      class="text-body-sm py-1 px-1.5 flex flex-col gap-1"
      :style="{ width: menuWidth }"
      :dense="dense"
    >
      <PItem
        v-if="search"
        class="p-0"
        @click.stop
      >
        <BaseInputField
          v-model="query"
          name="Search"
          class="selector-input"
          leading-icon="search-lg"
          placeholder="Search"
          autofocus
          :dense="dense"
          @click.stop
        />
      </PItem>
      <PItem
        v-if="search && !filteredItems.length"
        class="select-none"
        :dense="dense"
      >
        <PItemSection>No matching options found</PItemSection>
      </PItem>
      <component
        :is="itemComponent[item.type]"
        v-for="(item, index) in filteredItems"
        :key="index"
        v-bind="item.props"
        v-close-popup="item.props?.closeOnClick ? 1 : 0"
        :clickable="!item.props?.disable && item.props?.clickable"
        :class="{
          'cursor-pointer': item.props?.clickable && !item.props?.disable,
          '!my-1 !bg-proxify-gray-200': item.type === 'separator',
          '!min-h-[42px] rounded-full ': item.type === 'item',
          'bg-proxify-gray-50 !text-proxify-midnight-blue':
            item.props?.selected,
          'hover:bg-proxify-gray-50 !hover:text-proxify-midnight-blue':
            item.type === 'item' && !item.props?.disable,
          'cursor-not-allowed text-proxify-gray-300':
            item.type === 'item' && item.props?.disable,
        }"
        :dense="dense"
      >
        <PItemSection
          v-if="item.leftSection"
          side
          :dense="dense"
        >
          <template v-if="item.leftSection?.icon?.name">
            <BaseIcon
              :name="item.leftSection?.icon?.name"
              :size="item.leftSection?.icon?.size ?? '16px'"
              :color="item.leftSection?.icon?.color"
            />
          </template>
        </PItemSection>

        <PItemSection>
          <q-item-label
            :class="{
              'text-proxify-gray-400 text-sm-medium': item.props?.disable,
            }"
            :dense="dense"
          >
            {{ item.label }}
          </q-item-label>
          <q-item-label
            v-if="item.subLabel && item.props?.disable"
            caption
            class="text-body-xs text-proxify-gray-400 font-normal"
          >
            {{ item.subLabel }}
          </q-item-label>
        </PItemSection>

        <PItemSection
          v-if="item.rightSection || item.props?.selected"
          side
          :dense="dense"
        >
          <template v-if="item.rightSection?.icon || item.props?.selected">
            <BaseIcon
              :name="item.props?.selected ? 'check' : item.rightSection?.icon"
              size="16px"
              :color="
                item.props?.selected ? 'primary' : item.rightSection?.color
              "
            />
          </template>
          <template v-else-if="item.rightSection?.text">
            <span class="text-proxify-gray-500 font-normal text-body-xs">
              {{ item.rightSection?.text }}
            </span>
          </template>
        </PItemSection>
      </component>
      <PItem
        v-if="$slots.footer"
        :dense="dense"
        class="!p-0"
      >
        <slot name="footer" />
      </PItem>
    </PList>
    <div
      v-else-if="type === 'date' || type === 'date-time'"
      class="flex"
      @click.stop
    >
      <BaseDate
        :range="multiple"
        :model-value="dateModelValue"
        :disable-past-dates="disablePastDates"
        @range-end="hide"
        @update:model-value="
          (val) =>
            !multiple ? (model = moment(val).toISOString()) : (model = val)
        "
        @click="type === 'date' && !multiple && hide()"
      />
      <TimePicker
        v-if="type === 'date-time' && model"
        class="!w-[160px]"
        :pick-time="
          (val) => {
            const time = val.substring(0, 5);
            const [hour, minute] = time.split(':');
            emit(
              'update:modelValue',
              moment(model).set({ hour, minute }).toISOString()
            );
            hide();
          }
        "
        :selected-date="moment(model)"
      />
    </div>
    <div
      v-else-if="isTypeNumeric || isTypeText"
      class="flex flex-col gap-y-2"
    >
      <div
        :class="
          multiple
            ? 'grid grid-cols-2 gap-4 px-4 pt-4'
            : 'flex flex-col gap-y-2 px-4 pt-4'
        "
      >
        <div
          v-for="(item, index) in inputFields"
          :key="index"
          class="flex flex-col gap-y-2"
        >
          <span
            class="text-body-sm font-medium text-proxify-gray-700 select-none"
          >
            {{ item.title }}
          </span>
          <BaseInputField
            v-model="inputValues[index]"
            :type="isTypeNumeric ? 'number' : type"
            v-bind="item"
            :show-error-icon="true"
            :autofocus="!index"
            :data-name="item.name"
            @click.stop
            @keyup.enter="onSubmit"
          />
          <div
            v-if="isTypeText"
            class="text-proxify-gray-600 font-normal text-body-sm select-none"
          >
            Make sure that you write the city name correctly.
          </div>
        </div>
      </div>
      <div
        v-if="isTypeNumeric && multiple && Object.values(errors)[0]"
        class="px-4 text-proxify-error-500 text-body-sm font-normal"
      >
        {{ Object.values(errors)[0] }}
      </div>
      <div class="flex flex-col border-t border-proxify-gray-200 p-4">
        <BaseButton
          label="Apply"
          color="primary"
          class="h-[40px]"
          rounded
          @click="onSubmit"
        />
      </div>
    </div>
  </PMenu>
</template>
<style lang="scss">
.selector-input {
  @apply rounded-none
  w-full;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  .q-field {
    &__inner {
      border: none !important;
      box-shadow: none !important;
      border-radius: unset !important;
    }
    &__control {
      border: none !important;
      box-shadow: none !important;
      border-radius: unset !important;
    }
  }
}
</style>
