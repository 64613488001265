<script setup>
import api from '@/api';
import { computed, ref, watch } from 'vue';
import {
  BaseButton,
  BaseInputDropdown,
  BaseInputField,
  BaseToggle,
  BaseLabelScheduler,
} from '@/components/Base';
import EmailPreviewCard from '@/components/Elements/Scaffold/EmailPreviewCard.vue';
import { PDialog } from '@/components/ProxifyUI';
import TemplateEditor from '@/components/Elements/Scaffold/TemplateEditor.vue';
import { stringUtils } from '@/utils';

const props = defineProps({
  applicationId: {
    type: Number,
    required: false,
    default: undefined,
  },
  shouldSendEmail: {
    type: Boolean,
    required: false,
    default: false,
  },
  shouldScheduleEmail: {
    type: Boolean,
    required: false,
    default: true,
  },
  sendEmailToggleText: {
    type: String,
    required: false,
    default: 'Send e-mail to the applicant',
  },
});

const emit = defineEmits(['update:reasons']);

const sendEmail = defineModel('sendEmail', { type: Boolean, default: false });
const details = defineModel('details', {
  type: String,
  default: null,
});
const templateToSend = defineModel('templateToSend', {
  type: Object,
  default: undefined,
});
const sendAction = defineModel('sendAction', {
  type: Object,
  default: undefined,
});
const detailsRequired = defineModel('detailsRequired', {
  type: Boolean,
  default: false,
});

const rejectionReasons = ref([]);
const mainRejectionReason = ref(null);
const otherRejectionReasons = ref([]);
const templates = ref([]);
const isLoading = ref(false);
const showPreview = ref(false);
const expanded = ref(false);

const initialize = async () => {
  isLoading.value = true;
  const rejectionResponse = await api.rejectionReasons.getAll(
    props.applicationId
  );
  const templateResponse = await api.applications.emails.getTemplates({
    id: props.applicationId,
  });
  rejectionReasons.value = rejectionResponse?.data?.data || [];
  templates.value = templateResponse?.data?.templates
    .filter(({ type }) => type === 'rejection')
    .map((item) => ({
      ...item,
      markdown: stringUtils.convertToDelta(item.markdown),
    }));
  isLoading.value = false;
};

const handleUpdateMainReason = (value) => {
  templateToSend.value = templates.value.find(
    (item) => item.template_name === value.template_name
  );
};

const onUpdateHTMLOutput = (value) => {
  templateToSend.value = {
    ...templateToSend.value,
    body: value,
  };
};

const reasons = computed(() => {
  return {
    ...(mainRejectionReason.value?.id
      ? {
          [mainRejectionReason.value.id]: {
            is_main: true,
          },
        }
      : {}),
    ...otherRejectionReasons.value.reduce((accumulator, item) => {
      return {
        ...accumulator,
        [item.id]: { is_main: false },
      };
    }, {}),
  };
});

const filteredRejectionReasons = computed(() => {
  const rejectionReasonIDs = Object.keys(reasons.value);
  return rejectionReasons.value.filter(
    (reason) => !rejectionReasonIDs.includes(reason.id.toString())
  );
});

watch(
  () => reasons.value,
  (newValue) => {
    detailsRequired.value =
      mainRejectionReason.value?.code === 'other' ||
      otherRejectionReasons.value.some((item) => item.code === 'other');
    emit('update:reasons', newValue);
  },
  { deep: true }
);

watch(
  () => props.applicationId,
  (newValue) => {
    if (newValue) {
      initialize();
    }
  },
  { immediate: true }
);
</script>
<template>
  <div class="flex flex-col gap-4">
    <div
      class="font-medium text-body-sm font-proxify-gray-700 flex flex-col gap-1.5"
    >
      <span>Main reason</span>
      <BaseInputDropdown
        v-model="mainRejectionReason"
        name="Main reason"
        :options="filteredRejectionReasons"
        map-options
        type="search"
        placeholder="Search"
        @update:model-value="handleUpdateMainReason"
      />
    </div>
    <div
      class="font-medium text-body-sm font-proxify-gray-700 flex flex-col gap-1.5"
    >
      <span>Other reasons (optional)</span>
      <BaseInputDropdown
        v-model="otherRejectionReasons"
        name="Other reasons"
        :options="filteredRejectionReasons"
        map-options
        badge-size="sm"
        type="tags"
        placeholder="Search"
      />
    </div>
    <BaseButton
      v-if="!expanded && !detailsRequired"
      class="icon-link-color !p-0 !min-h-6 !h-6 items-start"
      icon-prepend="plus"
      icon-size="20px"
      data-testid="add-details-button"
      @click="() => (expanded = true)"
    >
      Add details
    </BaseButton>
    <div
      v-else-if="expanded || detailsRequired"
      class="font-medium text-body-sm font-proxify-gray-700 flex flex-col gap-1.5"
    >
      <span>
        Add details
        <span v-if="!detailsRequired">(optional)</span>
      </span>
      <BaseInputField
        v-model="details"
        name="Rejection details"
        class="action-link"
        type="textarea"
      ></BaseInputField>
    </div>
    <BaseToggle
      v-if="shouldSendEmail"
      v-model="sendEmail"
    >
      <div class="text-body-sm">
        <div class="font-medium text-proxify-gray-700">
          {{ sendEmailToggleText }}
        </div>
        <div class="font-normal text-proxify-gray-600">
          You will see this e-mail under e-mails tab.
        </div>
      </div>
    </BaseToggle>
    <template v-if="sendEmail">
      <div class="flex flex-col gap-4">
        <div
          class="font-medium text-body-sm font-proxify-gray-700 flex flex-col gap-1.5"
        >
          <span>Email template</span>
          <BaseInputDropdown
            v-model="templateToSend"
            name="Rejection reasons"
            :options="templates"
            map-options
            option-label="name"
            option-value="id"
            type="search"
            placeholder="Search"
          >
            <template #after>
              <BaseButton
                class="icon-tertiary-gray border-none p-2.5 trailing-button"
                icon-prepend="eye"
                @click="showPreview = true"
              />
            </template>
          </BaseInputDropdown>
        </div>
        <div
          class="font-medium text-body-sm font-proxify-gray-700 flex flex-col gap-1.5"
        >
          <div class="field-title">Edit email before sending</div>
          <TemplateEditor
            ref="templateEditor"
            v-model="templateToSend"
            :on-update-h-t-m-l="onUpdateHTMLOutput"
          />
        </div>
      </div>
      <BaseLabelScheduler
        v-if="shouldScheduleEmail"
        v-model="sendAction"
      />
    </template>
  </div>
  <!-- Email Preview -->
  <PDialog
    v-model="showPreview"
    position="standard"
  >
    <EmailPreviewCard
      :title="templateToSend?.name"
      :template="templateToSend?.template"
      :on-close="() => (showPreview = false)"
    />
  </PDialog>
</template>
