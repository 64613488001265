import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import '@fontsource-variable/inter';
import '@fontsource/poppins/300.css';
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/600.css';
import '@fontsource/poppins/700.css';

import { Quasar } from 'quasar';
import QuillEditor from '@/plugins/quill';
import Socket from '@/plugins/socket';
import Sentry from '@/plugins/sentry';
import VeeValidate from '@/plugins/vee-validate';
import globalMixin from '@/mixins/globalMixin';
import hotjar from '@/plugins/hotjar';
import sanitizer from '@/plugins/sanitizer';
import datadog from '@/plugins/datadog';

import 'quasar/src/css/index.sass';
import '@/styles/index.css';
import '@/styles/quasar.scss';

// Register global components
const app = createApp(App);
const modules = import.meta.glob('./components/App/**/*.vue', { eager: true });

for (const path in modules) {
  const component = modules[path]?.default;
  // Use the component's name if available, otherwise deduce from its file path
  app.component(
    component.name || path.split('/').pop().split('.')[0],
    component
  );
}

app.mixin(globalMixin);

app.config.errorHandler = (error) => {
  if (error.name === 'ChunkLoadError') {
    window.location.reload();
  }
};

app.use(Quasar, {});
app.use(store);
app.use(router);
app.use(QuillEditor);
app.use(Socket);
app.use(Sentry);
app.use(VeeValidate);
app.use(hotjar);
app.use(sanitizer);
app.use(datadog);
app.mount('#app');
