<template>
  <div class="transition duration-500">
    <ValueSelectorTrigger
      ref="datePickerSelectorRef"
      v-model="formattedDate"
      name="Date and Time"
      has-title
      :active="activeDatePicker"
      :on-toggle="
        () => {
          activeDatePicker = !activeDatePicker;
        }
      "
    />
    <transition name="fade">
      <DatePicker
        v-if="activeDatePicker"
        ref="datePickerRef"
        :target-element="datePickerSelectorRef"
        menu-placement="right-start"
        :pick-time="pickTime"
        :menu-offset-x="0"
        class="absolute"
        lite
        time-picker
        block-previous-days
        @pick="onDatePickerChange"
      />
    </transition>
  </div>
</template>

<script setup>
import DatePicker from '@/components/Elements/Scaffold/DatePicker.vue';
import ValueSelectorTrigger from '@/components/Elements/Scaffold/ValueSelectorTrigger.vue';
import { computed, onMounted, ref, watch } from 'vue';
import { onClickOutside } from '@vueuse/core';
import { dateTimeUtils } from '@/utils';
const { getTimezone } = dateTimeUtils;
import moment from 'moment';

const props = defineProps({
  outputFormat: {
    type: String,
    default() {
      return 'isoString';
    },
  },
  modelValue: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(['update:modelValue']);

const activeDatePicker = ref();
const date = ref();
const datePickerRef = ref();
const datePickerSelectorRef = ref();
const dayAndMonth = ref();
const time = ref();

onMounted(() => {
  date.value = moment().format('YYYY-MM-DD');
  dayAndMonth.value = moment().format('D MMMM');
  time.value = `${roundToNearest30()} ${getTimezone()}`;
});

const formattedDate = computed({
  get: () => {
    const tm = time.value ? `, ${time.value}` : '';
    return dayAndMonth.value + tm;
  },
});

const formattedTime = computed(() => {
  return time.value ? `${time.value.substring(0, 5)}:00` : '';
});

const onDatePickerChange = ({ obj }) => {
  date.value = obj.format('YYYY-MM-DD');
  dayAndMonth.value = obj.format('D MMMM');
  if (obj.isSame(new Date(), 'day')) {
    time.value = `${roundToNearest30()} ${getTimezone()}`;
  }
};

const pickTime = (tm) => {
  time.value = tm;
  activeDatePicker.value = !activeDatePicker.value;
};

const roundToNearest30 = () => {
  return moment()
    .minute(30 * Math.ceil(moment().minute() / 30))
    .format('HH:mm');
};

const setDateTimePickerValue = () => {
  const { outputFormat } = props;
  if (outputFormat !== 'isoString') {
    return emit(
      'update:modelValue',
      moment(
        `${date.value} ${formattedTime.value}`,
        'YYYY-MM-DD hh:mm:ss'
      ).format(outputFormat)
    );
  }
  emit(
    'update:modelValue',
    moment(
      `${date.value} ${formattedTime.value}`,
      'YYYY-MM-DD hh:mm:ss'
    ).toISOString()
  );
};

watch(
  () => formattedDate.value,
  () => {
    setDateTimePickerValue();
  }
);

onClickOutside(datePickerRef, () => (activeDatePicker.value = false), {
  ignore: [datePickerRef, datePickerSelectorRef],
});
</script>
