<template>
  <div class="flex flex-row mt-8">
    <PDialog
      v-model="isConfirmationDialogOpen"
      position="standard"
      persistent
    >
      <ConfirmationPopup
        :on-cancel="onCancelStageChange"
        :is-loading="isStageChanging"
        :is-interviewer-required="assigneesRequiredForStageChange.interviewer"
        :initial-recruiter="currentAssignees.recruiter"
        :initial-interviewer="currentAssignees.interviewer"
        :is-recruiter-required="assigneesRequiredForStageChange.recruiter"
        :application-id="stageChangeEventContext.applicationId"
        :on-update-recruiter="handleUpdateRecruiter"
        :new-stage="stageChangeEventContext.nextStage"
        :current-stage="stageChangeEventContext.previousStage"
        :country="stageChangeEventContext.application?.country"
        :monthly-rate="
          stageChangeEventContext.application?.rate?.monthly_rate_updated ??
          stageChangeEventContext.application?.rate?.monthly_rate
        "
        :gig-overtime-hourly-rate="
          stageChangeEventContext.application?.rate
            ?.gig_overtime_hourly_rate_updated ??
          stageChangeEventContext.application?.rate?.gig_overtime_hourly_rate
        "
        :is-rate-guidance-enabled="
          stageChangeEventContext.application?.compensation_plan === 'monthly'
        "
        :applicant-name="`${stageChangeEventContext.application?.first_name} ${stageChangeEventContext.application?.last_name}`"
        @change-stage="onConfirmStageChange"
      />
    </PDialog>
    <PDialog
      v-model="isStageActionDialogOpen"
      position="standard"
      persistent
    >
      <StageActionPopup
        :on-cancel="onStageActionClose"
        :on-confirm="onStageActionPerform"
        :is-loading="isStageActionLoading"
        :actions="stageEmailActions"
        :application-id="stageChangeEventContext.applicationId"
      />
    </PDialog>
    <PDialog
      v-model="isReminderDialogOpen"
      position="standard"
      persistent
    >
      <ReminderPopup
        :on-cancel="onReminderCancel"
        :on-close="onReminderClose"
        :on-confirm="onReminderConfirm"
      />
    </PDialog>
    <ApplicationForm
      v-if="stageChangeEventContext?.application?.source"
      ref="formDialog"
      :initial-data="stageChangeEventContext?.application"
      :on-submit="handleSubmitApplicationForm"
      :upload-progress="uploadProgress"
      title="Update applicant information"
      :previous-stage-name="stageChangeEventContext?.previousStage?.name"
      :next-stage-name="stageChangeEventContext?.nextStage?.name"
      @cancel="onCancelStageChange"
    ></ApplicationForm>

    <div
      ref="applicationsWrapper"
      class="flex flex-col gap-6 w-full"
      style="padding-bottom: unset"
    >
      <div class="flex items-center gap-2 mx-8">
        <span class="text-display-xs font-poppins text-proxify-gray-900">
          Applications
        </span>
        <BaseBadge
          v-if="totalApplications"
          color="primary"
        >
          {{ totalApplications }}
        </BaseBadge>
      </div>
      <ApplicationFilter />
      <div
        ref="ghostElement"
        class="w-0 h-0 -mt-6"
      ></div>
      <div class="content">
        <KanbanView
          v-if="kanban"
          :on-before-stage-change="onBeforeStageChange"
          :update-stage-applications="updateStageApplications"
          :container-height="containerHeight"
          :is-loading="isLoading"
          @open-share-link-dialog="openShareLinkDialog"
        />
        <ListView
          v-else
          :on-before-stage-change="onBeforeStageChange"
          :recently-changed-stage="recentlyChangedStage"
          :recently-changed-recruiter="recentlyChangedRecruiter"
          :container-height="containerHeight"
          :is-a-p-i-loading="isLoading"
          @open-share-link-dialog="openShareLinkDialog"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import { useElementBounding, useClipboard } from '@vueuse/core';
import useNormalizedWindowSize from '@/composables/useNormalizedWindowSize';
import KanbanView from '@/components/Layout/Application/KanbanView.vue';
import ListView from '@/components/Layout/Application/ListView.vue';
import ConfirmationPopup from '@/components/Elements/Application/ApplicationStageChangeConfirmationPopup.vue';
import StageActionPopup from '@/components/Elements/Application/StageActionPopup.vue';
import ReminderPopup from '@/components/Elements/Application/ReminderPopup.vue';
import ApplicationFilter from '@/components/Elements/Application/ApplicationFilteringArea.vue';
import { mapState, useStore } from 'vuex';
import api from '@/api';
import snackbarMessagesMixin from '@/mixins/snackbarMessagesMixin';
import titleMixin from '@/mixins/titleMixin';
import { PDialog } from '@/components/ProxifyUI';
import { BaseBadge } from '@/components/Base';
import ApplicationForm from '@/components/Elements/Application/ApplicationForm.vue';

export default {
  name: 'TheApplications',
  title: 'Applications',
  components: {
    ApplicationForm,
    PDialog,
    ApplicationFilter,
    KanbanView,
    ListView,
    ConfirmationPopup,
    StageActionPopup,
    ReminderPopup,
    BaseBadge,
  },
  mixins: [snackbarMessagesMixin, titleMixin],
  provide() {
    return {
      codilityTests: computed(() => this.codilityTests),
    };
  },
  setup() {
    const applicationsWrapper = ref(null);
    const ghostElement = ref(null);
    const store = useStore();

    const { top } = useElementBounding(ghostElement, { windowScroll: false });
    const { height } = useNormalizedWindowSize();
    const containerHeight = computed(() => {
      return height.value - top.value + 24;
    });

    const { copy } = useClipboard();

    const openShareLinkDialog = async (applicationId) => {
      const url = `${import.meta.env.VITE_URL}/applications/${applicationId}`;
      await copy(url);

      store.commit('ui/addSnackbarMessage', {
        title: 'Link copied to clipboard',
        type: 'success',
      });
    };

    return {
      ghostElement,
      applicationsWrapper,
      containerHeight,
      top,
      height,
      openShareLinkDialog,
    };
  },
  data() {
    return {
      isLoading: false,
      showSearch: false,
      hideUntilNextVersion: true,
      initialContainers: [],
      stageChangeEventContext: {},
      isStageChanging: false,
      isStageActionLoading: false,
      isStageActionDialogOpen: false,
      isReminderDialogOpen: false,
      isConfirmationDialogOpen: false,
      stageEmailActions: [],
      currentAssignees: {},
      uploadProgress: 100,
      recentlyChangedStage: {},
      recentlyChangedRecruiter: {},
      codilityTests: [],
      codilityTestsAreLoading: false,
    };
  },
  computed: {
    ...mapState({
      filterQueries: (state) => state.application.filterQueries,
      recruiters: (state) => state.applicant.recruiters,
      currentUser: (state) => state.auth.user,
      containers: (state) => state.application.containers,
      totalApplications: (state) => state.application.total,
      interviewers: (state) => state.application.interviewers,
      sourcers: (state) => state.applicant.sourcers,
      sources: (state) => state.application.sources,
      stages: (state) => state.applicant.stages,
      skills: (state) => state.applicant.skills,
      countries: (state) => state.applicant.countries,
      industries: (state) => state.applicant.industries,
      rejectionReasons: (state) => state.applicant.rejectionReasons,
      users: (state) => state.applicant.users,
      isSidebarActive: (state) => state.settings.isSidebarActive,
      kanban: (state) => state.settings.kanban,
      showStagesItems: (state) => state.settings.showStages?.items,
    }),
    assigneesRequiredForStageChange() {
      const { nextStageId } = this.stageChangeEventContext;
      const stage = (this.stages ?? this.containers)?.find(
        ({ id }) => id === parseInt(nextStageId, 10)
      );
      return {
        interviewer: Boolean(stage?.interviewer_required),
        recruiter: Boolean(stage?.recruiter_required),
      };
    },
  },
  watch: {
    filterQueries: {
      async handler(filterQueries) {
        if (this.$store.getters['auth/isLogged']) {
          await this.getContainers(filterQueries, true);
        }
      },
      deep: true,
    },
    stageChangeEventContext: {
      async handler(newValue) {
        if (
          newValue.nextStageId &&
          newValue.nextStage?.name === 'Technical Assessment'
        ) {
          await this.getTests();
        }
      },
      deep: true,
    },
  },
  created() {
    this.getStages();
    this.getAssignees();
    this.getSources();
    this.getSourcers();
    this.getSkills();
    this.getCountries();
    this.getIndustries();
    this.getRecruiters();
    this.getRejectionReasons();
    this.getUsers();
    this.$store.commit('appTraffic/setCurrentRoute', [
      { name: 'Applications', path: '/applications', icon: 'file-search02' },
    ]);

    if (Object.keys(this.$route.query).length) {
      this.$store.commit('application/setFilterQueries', this.$route.query);
      this.$router.push({ ...this.$route, query: {} });
    } else if (Object.keys(this.filterQueries).length === 0) {
      const query = JSON.parse(localStorage.getItem('filterQueries'));
      if (Object.keys(query ?? {}).length > 0) {
        this.$store.commit('application/setFilterQueries', query);
      } else {
        this.setDefaultFilters();
      }
    } else {
      this.getContainers(this.filterQueries, true);
    }
  },
  methods: {
    async getContainers(filterQueries, refresh = true) {
      this.isLoading = refresh;
      await this.$store.dispatch('application/getContainers', {
        params: filterQueries,
      });
      this.isLoading = false;
      this.initialContainers = JSON.parse(JSON.stringify(this.containers));
    },
    setDefaultFilters() {
      const { currentUser, recruiters, sourcers } = this;
      const isRecruiter = (recruiters?.recruiters ?? []).some(
        ({ id }) => id === currentUser.id
      );
      const isSourcer = sourcers.some(({ id }) => id === currentUser.id);
      const defaultFilters = {
        ['filters[0][rejected:is]']: String(0),
        ...(isRecruiter
          ? { ['filters[0][recruiter:is]']: String(currentUser.id) }
          : {}),
        ...(isSourcer
          ? { ['filters[0][sourcer:is]']: String(currentUser.id) }
          : {}),
      };

      const query = JSON.parse(localStorage.getItem('filterQueries') || '{}');
      if (Object.keys(query).length === 0) {
        this.$store.commit('application/setFilterQueries', defaultFilters);
      }
    },
    onUploadProgress({ loaded, total }) {
      this.uploadProgress = Math.ceil((loaded / total) * 100);
    },
    async handleSubmitApplicationForm({ skills, ...form }) {
      const id = this.stageChangeEventContext.applicationId;
      const { onUploadProgress } = this;
      await api.applications.putApplication(id, form);
      if (skills && skills.length) {
        await api.applications.updateSkills(id, { skills });
      }
      if (form.sourcer_id) {
        await api.applications.updateAssignees(id, {
          sourcer_id: form.sourcer_id,
        });
      }
      if (form.attachments) {
        const data = new FormData();
        Object.keys(form.attachments).forEach((key) => {
          if (form.attachments[key]) {
            if (
              Object.prototype.toString
                .call(form.attachments[key])
                .slice(8, -1) === 'File'
            ) {
              data.append(key, form.attachments[key]);
            }
          }
        });
        await api.applications.updateAttachments(data, {
          id,
          onUploadProgress,
        });
      }
      this.isConfirmationDialogOpen = true;
      this.showSuccessMessage('Applicant information has been updated!', 3000);
      this.$store.commit('applicant/setDataNeedsUpdate');
    },
    async onConfirmStageChange({
      interviewerId,
      recruiterId,
      skipInterview,
      interviewerGroup,
      recruiterGroup,
    }) {
      const {
        applicationId,
        application,
        previousStageId,
        nextStageId,
        nextStage,
      } = this.stageChangeEventContext;
      this.isStageChanging = true;
      const interviewerMap = [
        { condition: !nextStage.interview_required, value: undefined },
        { condition: nextStage.interviewer_required, value: interviewerId },
        { condition: !nextStage.interviewer_required, value: recruiterId },
      ];
      const groupMap = [
        { condition: !nextStage.interview_required, value: undefined },
        { condition: nextStage.interviewer_required, value: interviewerGroup },
        { condition: !nextStage.interviewer_required, value: recruiterGroup },
      ];
      const { data } = await api.applications.changeStage(applicationId, {
        stage_id: nextStageId,
        interviewer_id: interviewerMap.find(({ condition }) => condition)
          ?.value,
        interview_group_id: groupMap.find(({ condition }) => condition)?.value,
        skip_interview: skipInterview,
      });
      this.stageEmailActions = data.actions?.filter(
        ({ action }) => action === 'email'
      );
      if (this.stageEmailActions.length) {
        this.isStageActionDialogOpen = true;
      }
      this.updateStageApplications({
        stage_from: previousStageId,
        stage_to: nextStageId,
        application: {
          ...application,
          recruiter_id: recruiterId,
        },
      });
      this.recentlyChangedStage = {
        applicationId,
        stageId: nextStageId,
      };
      this.isStageChanging = false;
      this.isConfirmationDialogOpen = false;
      this.initialContainers = JSON.parse(JSON.stringify(this.containers));
      this.showSuccessMessage('Status was updated successfully');
    },
    onCancelStageChange() {
      this.$store.commit(
        'application/setContainers',
        JSON.parse(JSON.stringify(this.initialContainers))
      );
      this.stageChangeEventContext.fallbackFunction?.();
      this.getContainers(this.filterQueries, false);
      this.isConfirmationDialogOpen = false;
      this.stageChangeEventContext = {};
    },
    async onBeforeStageChange({
      previousStageId,
      nextStageId,
      applicationId,
      fallbackFunction,
    }) {
      this.initialContainers = JSON.parse(JSON.stringify(this.containers));
      const previousStage = (this.stages ?? this.containers)?.find(
        ({ id }) => id === parseInt(previousStageId, 10)
      );
      const nextStage = (this.stages ?? this.containers)?.find(
        ({ id }) => id === parseInt(nextStageId, 10)
      );
      const { data: { response: application } = {} } =
        await api.applications.getApplicant(applicationId);
      this.currentAssignees = {
        recruiter: application?.assignees?.recruiter?.id,
        interviewer: application?.interviewers?.[nextStageId]?.interviewer,
      };
      this.stageChangeEventContext = {
        previousStageId,
        previousStage,
        nextStageId,
        nextStage,
        applicationId,
        application,
        fallbackFunction,
      };
      await this.$nextTick();

      const cvFile = (application.attachments ?? []).find(
        ({ type }) => type === 'cv_file'
      );

      const requiredFields = [
        application.first_name,
        application.last_name,
        application.email,
        application.country,
        application.source,
        application.assignees?.sourcer?.id,
        application.social_links?.linkedin_link || cvFile,
        application.application_skills.length > 0,
        application.commercial_exp_months,
        application.english_level,
        application.competency,
        application.compensation_plan === 'monthly'
          ? application.rate?.monthly_rate &&
            application.rate?.gig_overtime_hourly_rate
          : application.rate?.hourly_rate,
        application.application_skills.every((skill) =>
          Boolean(skill.years && skill.proficiency_level)
        ),
      ];
      const valid = requiredFields.every(Boolean);
      const isSourcedApplication = application.source === 'sourced';
      if (
        previousStage.interview_required &&
        !application?.interviewers?.[previousStage.id]?.filled
      ) {
        this.isReminderDialogOpen = true;
      } else if (
        previousStage.is_sourcing &&
        !nextStage.is_sourcing &&
        isSourcedApplication &&
        !valid
      ) {
        await this.$refs.formDialog.reveal();
      } else {
        this.isConfirmationDialogOpen = true;
      }
    },
    async onStageActionPerform(file, selectedAction, sendAt) {
      const { applicationId } = this.stageChangeEventContext;
      const { id, link } = selectedAction;
      this.isStageActionLoading = true;
      let formData = new FormData();
      formData.append('data[0][action_id]', id);
      if (file) {
        formData.append('data[0][file]', file);
      }
      if (link) {
        formData.append('data[0][link]', link);
      }
      if (sendAt) {
        formData.append('data[0][send_at]', sendAt);
      }
      await api.applications.performTriggerActions(applicationId, formData);
      this.onStageActionClose();
      this.showSuccessMessage('Email was sent successfully');
    },
    onStageActionClose() {
      this.stageEmailActions = [];
      this.isStageActionLoading = false;
      this.stageChangeEventContext = {};
      this.currentAssignees = {};
      this.isStageActionDialogOpen = false;
    },
    onReminderConfirm() {
      this.$router.push({
        path: `/applications/${this.stageChangeEventContext.applicationId}/interviews`,
      });
    },
    onReminderCancel() {
      this.isReminderDialogOpen = false;
      this.isConfirmationDialogOpen = true;
    },
    onReminderClose() {
      this.$store.commit(
        'application/setContainers',
        JSON.parse(JSON.stringify(this.initialContainers))
      );
      this.isReminderDialogOpen = false;
    },
    handleUpdateRecruiter({ applicationId, recruiterId }) {
      this.recentlyChangedRecruiter = {
        applicationId,
        recruiterId,
      };
    },
    async getAssignees() {
      if (!this.interviewers) {
        const { data: interviewers } = await api.users.techInterviewers();
        this.$store.commit('application/setInterviewers', interviewers.data);
      }
    },
    async getSources() {
      if (!this.sources.length) {
        const { data: sources } = await api.data.sources();
        this.$store.commit('application/setSources', sources.data);
      }
    },
    async getSourcers() {
      if (!this.sourcers.length) {
        const { data: sourcers } = await api.users.sourcers();
        this.$store.commit('applicant/setSourcers', sourcers.data);
      }
    },
    async getSkills() {
      if (!this.skills.length) {
        const { data: skills } = await api.data.skills();
        this.$store.commit('applicant/setSkills', skills.data);
      }
    },
    async getCountries() {
      if (!this.countries.length) {
        const { data: countries } = await api.data.countries();
        this.$store.commit('applicant/setCountries', countries.countries);
      }
    },
    async getIndustries() {
      if (!this.industries.length) {
        const { data: { industries } = {} } = await api.data.industries();
        this.$store.commit('applicant/setIndustries', industries);
      }
    },
    async getRecruiters() {
      if (!this.recruiters) {
        const { data: recruiters } = await api.users.recruiters();
        this.$store.commit('applicant/setRecruiters', recruiters.data);
      }
    },
    async getStages() {
      const { stages, showStagesItems } = this;
      if (!stages.length) {
        const { data: stagesResponse } = await api.stages.getStages();
        this.$store.commit('applicant/setStages', stagesResponse.data);
        this.$store.dispatch(
          'settings/setStagesDefaultShowHideStatus',
          stagesResponse.data
        );
      } else if (
        !showStagesItems ||
        Object.values(showStagesItems)?.[0]?.value === null
      ) {
        this.$store.dispatch('settings/setStagesDefaultShowHideStatus', stages);
      }
    },
    async getRejectionReasons() {
      if (!this.rejectionReasons.length) {
        const { data: reasons } = await api.rejectionReasons.getAll();
        this.$store.commit('applicant/setRejectionReasons', reasons.data);
      }
    },
    async getUsers() {
      if (!this.users.length) {
        const { data: users } = await api.users.getAll();
        this.$store.commit('applicant/setUsers', users.data);
      }
    },
    async getTests() {
      if (this.codilityTests.length || this.codilityTestsAreLoading) {
        return;
      }
      this.codilityTestsAreLoading = true;
      const { data: { tests } = {} } =
        await api.applications.codility.getTests();
      const filterBlocks = ['[OLD]', '[LIVE-CODING-ONLY]', '[TEST-DAY-ONLY]'];
      this.codilityTests = tests.filter(
        ({ name }) => !filterBlocks.some((item) => name.includes(item))
      );
      this.codilityTestsAreLoading = false;
    },
    updateStageApplications(socketEvent) {
      /*
        socketEvent structure:
        {
          stage_from: {
            id: 1,
            total: 8,
          },
          stage_to: {
            id: 2,
            total: 10,
          },
          application: {
           ...application,
          },
        }
      */
      if (socketEvent.stage_from) {
        this.$store.commit(
          'application/setCounterNeedUpdate',
          socketEvent.stage_from.id ?? parseInt(socketEvent.stage_from, 10)
        );
        this.$store.commit('application/removeFromNewApplications', {
          stageId:
            socketEvent.stage_from.id ?? parseInt(socketEvent.stage_from, 10),
          application: socketEvent.application,
        });
      }
      this.$store.commit(
        'application/setCounterNeedUpdate',
        socketEvent.stage_to.id ?? parseInt(socketEvent.stage_to, 10)
      );
      this.$store.commit('application/moveApplication', {
        application: {
          ...socketEvent.application,
          uuid:
            socketEvent.application.applicant?.uuid ??
            `app-${socketEvent.application.id}`,
        },
        stageTo: socketEvent.stage_to.id ?? parseInt(socketEvent.stage_to, 10),
      });
      if (!socketEvent.application.is_rejected) {
        this.$store.commit('application/addToNewApplications', {
          stageId:
            socketEvent.stage_to.id ?? parseInt(socketEvent.stage_to, 10),
          application: socketEvent.application,
        });
      }
    },
  },
};
</script>
