<template>
  <div :data-name="name">
    <div class="font-semibold">
      <span>
        {{ title }}
      </span>
      <span
        v-if="required"
        class="text-disabled-normal font-normal text-xs"
      >
        *required
      </span>
    </div>
    <div class="flex flex-row my-2">
      <StarRating
        :id="id"
        :value="modelValue"
        :spacing="0.125"
        :height="1.5"
        :disabled="disabled"
        :interactive="!readOnly"
        @set-rating-value="(response) => handleChange(response.value)"
      />
    </div>
    <ErrorMessage
      v-slot="{ message }"
      :name="name"
    >
      <span class="text-ats-red text-sm">{{ message }}</span>
    </ErrorMessage>
  </div>
</template>

<script>
import StarRating from '@/components/Icons/IconStarRating.vue';
import { useField } from 'vee-validate';
import { toRefs } from 'vue';

export default {
  name: 'AppCardRatingSelector',
  components: {
    StarRating,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      required: false,
    },
    name: {
      type: String,
      required: false,
    },
    required: {
      type: Boolean,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
    readOnly: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
    modelValue: {
      type: Number,
      required: false,
      default() {
        return 0;
      },
    },
  },
  emits: ['update:modelValue'],
  setup(props) {
    const { required } = toRefs(props);
    const { handleChange, value } = useField(
      props.name,
      {
        required: required.value,
        min_value: 1,
      },
      {
        initialValue: props.modelValue,
      }
    );
    return { handleChange, value };
  },
};
</script>
