import { ref } from 'vue';
import { Loader } from '@googlemaps/js-api-loader';

export default function useCities() {
  const cityList = ref([]); // List of cities
  const fetchError = ref(null); // Any errors that occur during fetch
  const googleMapsLoaded = ref(false); // Tracks if the Google Maps API has loaded

  // Google Maps API loader setup
  const googleMapsLoader = new Loader({
    apiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
    language: 'en',
    version: 'weekly',
    authReferrerPolicy: 'origin',
    libraries: ['places'],
  });

  const clearCityList = () => {
    cityList.value = [];
  };

  const fetchCities = async (selectedCountry, inputCity) => {
    if (!inputCity || inputCity.length < 2 || !selectedCountry) {
      clearCityList(); // Clear the city list if the input is too short or wno country code is provided
      return;
    }

    try {
      googleMapsLoaded.value = true;
      // Load the Google Maps API
      const google = await googleMapsLoader.load();

      // Set up the Google Maps Autocomplete service
      const autoCompleteService = new google.maps.places.AutocompleteService();

      // Fetch city predictions based on the input
      await autoCompleteService.getPlacePredictions(
        {
          input: inputCity,
          componentRestrictions: { country: selectedCountry.code },
          language: 'en',
          fields: ['name'],
          types: ['locality'],
        },
        (predictions, status) => {
          if (status !== google.maps.places.PlacesServiceStatus.OK) {
            googleMapsLoaded.value = false;
            cityList.value = []; // Clear the city list if there was an error
            return;
          }

          const uniquePredictions = new Set(
            predictions.map(
              (p) =>
                p.terms[p.terms.length - 2]?.value ??
                p.structured_formatting?.main_text
            )
          );

          // Map the prediction results into a more usable format
          cityList.value = [...uniquePredictions].map((city) => {
            return {
              id: city,
              name: city,
              code: city,
            };
          });
        }
      );
    } catch (error) {
      console.log(error);
      googleMapsLoaded.value = false;
      fetchError.value = error;
    }
  };

  // Expose the city list, fetch function, and any errors for use in the component
  return { cityList, clearCityList, fetchCities, googleMapsLoaded, fetchError };
}
