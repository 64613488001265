import { createRouter, createWebHistory } from 'vue-router';
import Login from '../views/Login.vue';
import ResetPassword from '../views/ResetPassword.vue';
import HomePage from '../views/HomePage.vue';
import Applications from '../views/Applications.vue';
import Applicant from '../views/Applicant.vue';
import ApplicantActivityInterviewScorecardLayout from '@/components/Elements/Applicant/ApplicantActivityInterviewScorecardLayout.vue';
import store from '../store';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomePage,
    meta: { requiresAuth: true },
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { requiresAuth: false },
  },
  {
    path: '/logout',
    name: 'Logout',
    beforeEnter(_, __, next) {
      store.dispatch('auth/logout').then(() => {
        return next('/login');
      });
    },
  },
  {
    path: '/reset',
    name: 'Reset',
    component: ResetPassword,
    meta: { requiresAuth: false },
  },
  {
    path: '/applications',
    name: 'Applications',
    component: Applications,
    meta: { requiresAuth: true },
  },
  {
    path: '/applications/:id/emails/:subject?/:itemId?',
    name: 'ApplicantEmails',
    component: Applicant,
    meta: { requiresAuth: true },
  },
  {
    path: '/applications/:id/:tab?/:itemId?',
    name: 'Applicant',
    component: Applicant,
    meta: { requiresAuth: true },
  },
  {
    path: '/applications/:id/scorecard/:interviewId?',
    name: 'Scorecard',
    component: ApplicantActivityInterviewScorecardLayout,
    meta: { requiresAuth: true, requiresInitialData: true },
  },
  {
    path: '/scheduler/:id',
    name: 'Scheduler',
    component: () => import('../views/Scheduler.vue'),
    meta: { requiresAuth: false },
  },
  {
    path: '/scheduler/static/:alias',
    name: 'Static Scheduler',
    component: () => import('../views/StaticScheduler.vue'),
    meta: { requiresAuth: true },
  },

  // Page Not Found
  {
    path: '/error/:code',
    name: 'Error',
    component: () => import('../views/Error.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/:catchAll(.*)',
    redirect: { name: 'Error', params: { code: 404 } },
    meta: { requiresAuth: true },
  },
];
const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('token');
  const user = localStorage.getItem('user');
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (token && user) {
      store.commit('auth/login', { token, user: JSON.parse(user) });
      next();
    } else {
      next({ name: 'Logout' });
    }
  } else {
    next();
  }
});

export default router;
