<template>
  <div>
    <div
      ref="textData"
      class="flex flex-row flex-wrap justify-between w-full relative"
    >
      <div ref="title" :class="titleClass">{{ title }}</div>
      <div ref="valueData" :class="valueClass" class="value-class">
        {{ value }}
      </div>
      <div class="tooltiptext">
        <IconBase
          class="mx-1 mt-0.5"
          border-radius="0%"
          width="0.9"
          height="0.9"
        >
          <IconCalendar />
        </IconBase>
        {{ moment(date).format("DD MMMM, HH:mm") }}
      </div>
    </div>
  </div>
</template>

<script>
import IconCalendar from '@/components/Icons/IconCalendar.vue';
import IconBase from '@/components/Icons/IconBase.vue';
import moment from 'moment-timezone';

export default {
  name: 'DaysData',
  components: {
    IconBase,
    IconCalendar,
  },
  props: {
    date: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      required: false,
    },
    titleClass: {
      type: [Object, String],
      required: false,
      default() {
        return { 'text-proxify-gray font-medium text-body-sm': true };
      },
    },
    value: {
      type: String,
      required: true,
    },
    valueClass: {
      type: [Object, String],
      required: false,
    },
  },
  created() {
    this.moment = moment;
  },
};
</script>

<style scoped>
.value-class {
  @apply relative inline-block text-right pl-1 text-proxify-gray font-medium text-body-sm;
}

.value-class + .tooltiptext {
  @apply py-2 px-3 flex bg-white text-disabled-dark whitespace-pre rounded absolute right-0 invisible bottom-full shadow-sm;

  transition: visibility 0.2s linear, opacity 0.2s linear;
  z-index: 1;
}

.value-class:hover + .tooltiptext {
  @apply visible;
}
</style>
