<script setup>
import { computed, toRefs } from 'vue';
import {
  BaseBadge,
  BaseRating,
  BaseIcon,
  BaseTooltip,
  BaseButton,
} from '@/components/Base';
import ApplicantSkillBadge from '@/components/Elements/Applicant/ApplicantSkillBadge.vue';
import { useStore } from 'vuex';
import { applicationUtils, dateTimeUtils } from '@/utils';
import moment from 'moment';

const props = defineProps({
  application: {
    type: Object,
    required: true,
  },
  cardColor: {
    type: String,
    required: false,
    default: undefined,
  },
  currentStageName: {
    type: String,
    required: false,
    default() {
      return '';
    },
  },
});

const emit = defineEmits(['open-share-link-dialog']);

const { application } = toRefs(props);

const store = useStore();

const applicantCardSettings = computed(
  () => store.getters['settings/applicantCardSettings']
);
const stages = computed(() => store.state.applicant.stages);

const { getTimeDifference } = dateTimeUtils;

const getStageName = (stageId) => {
  const stage = stages.value.find(({ id }) => id === stageId);
  return stage?.name ?? '';
};

const highDemandSecondarySkillExists = (application) => {
  const { secondarySkills } = applicationUtils.separateSkills(
    application.application_skills
  );

  return secondarySkills.some(
    (skill) => skill.supply_demand_ratio.label === 'High demand'
  );
};

const generateRejectionContent = (rejection) => {
  if (!rejection) return '';

  const reasons = (rejection.reasons ?? [])
    .filter((reason) => reason.name !== 'Other')
    .sort((a, b) => b.is_main - a.is_main)
    .map((reason) => `<strong>${reason.name}</strong>: ${reason.description}`);

  const details = rejection.details
    ? `<strong>${rejection.reasons?.some((reason) => reason.name === 'Other') ? 'Other' : 'Detail Added'}:</strong> ${rejection.details}`
    : '';

  return [...reasons, details].join('<br>');
};

const applicantInfoContext = computed(() => {
  const hiredInfo = {
    show: application.value?.is_hired,
    color: 'success',
    label: 'Joined Network',
    contentBody: `Member of the community since ${moment(application.value.hired_at).format('DD MMMM YYYY')}`,
  };

  const rejectionInfo = {
    show: application.value?.is_rejected,
    color: 'error',
    label: application.value?.rejection?.type,
    contentBody: generateRejectionContent(application.value?.rejection),
  };

  const notScheduledInfo = {
    show: application.value?.has_not_scheduled_meeting,
    color: 'warning',
    label: 'Not scheduled',
    contentBody:
      "This candidate hasn't scheduled the meeting for the current stage yet. You might need to resend an invite.",
  };

  return [hiredInfo, rejectionInfo, notScheduledInfo].find(({ show }) => show);
});
</script>
<template>
  <div class="card">
    <div
      v-if="cardColor"
      class="w-full rounded"
      :class="cardColor"
    ></div>
    <div class="title w-full">
      <BaseBadge
        v-if="application.fast_tracked"
        badge-class="!bg-proxify-primary-100 !text-proxify-primary-600"
        data-testid="fast-tracked-badge"
        size="sm"
        color="error"
      >
        <span class="text-body-xs font-bold">F</span>
        <BaseTooltip>
          <div class="relative flex flex-col gap-[4px]">
            <div class="text-body-xs font-semibold">Fast-tracked candidate</div>
            <p class="text-body-xs font-medium">
              This candidate is marked as fast-tracked, based on skills and
              background
            </p>
          </div>
        </BaseTooltip>
      </BaseBadge>
      <div class="truncate text-body-md font-bold text-proxify-gray-900">
        {{ application.first_name }} {{ application.last_name }}
      </div>
    </div>
    <BaseRating
      v-if="applicantCardSettings?.showOnCards.items.rating.value"
      :model-value="application.average_rating"
      read-only
      :name="application.uuid"
      class="h-5"
    />
    <BaseBadge
      v-if="applicantInfoContext?.show"
      :color="applicantInfoContext?.color"
      class="!mx-0 pl-[10px] pr-[6px] w-fit"
    >
      <span class="text-body-sm font-medium">
        {{ applicantInfoContext?.label }}
      </span>
      <template #append>
        <BaseIcon
          name="info-circle"
          :class="`text-proxify-${applicantInfoContext?.color}-500`"
          size="12px"
        />
        <BaseTooltip :offset="[0, 6]">
          <div class="relative flex flex-col gap-[4px]">
            <p class="text-body-xs font-medium">
              <span v-safe-html="applicantInfoContext?.contentBody"></span>
            </p>
          </div>
        </BaseTooltip>
      </template>
    </BaseBadge>
    <div
      v-if="applicantCardSettings?.showOnCards.items.skills.value"
      class="w-full flex items-center flex-wrap gap-[4px]"
      data-testid="application-skills"
    >
      <ApplicantSkillBadge
        v-for="(item, index) in applicationUtils.separateSkills(
          application.application_skills
        ).mainSkills"
        :key="index"
        :skill="item"
        :show-proficiency="false"
        :show-experience="true"
        class="!px-2.5 !py-0.5"
        label-class="!text-body-x flex-nowrap"
      />
      <BaseBadge
        v-if="
          applicationUtils.separateSkills(application.application_skills)
            .secondarySkills.length
        "
        badge-class="max-w-[168px] !px-2.5 !py-0.5 !text-body-xs cursor-default"
      >
        <template #append>
          <BaseBadge
            v-if="highDemandSecondarySkillExists(application)"
            size="sm"
            icon="chevron-up-double"
            color="success"
            icon-position="left"
            class="capitalize !text-body-sm !px-0 bg-transparent"
          />
          <BaseIcon
            name="plus"
            class="-mx-1.5"
            :class="{
              'text-proxify-success-600':
                highDemandSecondarySkillExists(application),
            }"
          />
          <span
            :class="{
              'text-proxify-success-600':
                highDemandSecondarySkillExists(application),
            }"
          >
            {{
              applicationUtils.separateSkills(application.application_skills)
                .secondarySkills.length
            }}
          </span>
          <BaseTooltip
            :offset="[0, 6]"
            tooltip-class="!max-h-[unset]"
          >
            <div class="font-semibold my-1">
              Secondary Skills ({{
                applicationUtils.separateSkills(application.application_skills)
                  .secondarySkills.length
              }})
            </div>
            <div class="flex flex-col gap-[8px]">
              <ApplicantSkillBadge
                v-for="(item, index) in applicationUtils.separateSkills(
                  application.application_skills
                ).secondarySkills"
                :key="index"
                :skill="item"
              />
            </div>
          </BaseTooltip>
        </template>
      </BaseBadge>
    </div>
    <div
      v-if="
        applicantCardSettings?.showOnCards.items.activity.value ||
        applicantCardSettings?.showOnCards.items.action.value
      "
      class="action flex w-full items-center"
    >
      <div
        v-if="applicantCardSettings?.showOnCards.items.activity.value"
        class="flex gap-[8px]"
      >
        <BaseBadge
          class="!p-0 !text-body-xs !font-semibold"
          dense
          :icon="application.auto_transition ? 'magic-wand01' : 'activity'"
          color="transparent"
        >
          {{ getTimeDifference(application.last_activity) }}
          <BaseTooltip v-if="application.auto_transition">
            <div class="relative flex flex-col gap-[4px]">
              <div class="text-body-xs font-semibold">Auto transition</div>
              <p class="text-body-xs font-medium">
                This candidate was automatically moved from
                {{ getStageName(application.auto_transition?.from_stage_id) }}
                to {{ currentStageName }}
              </p>
            </div>
          </BaseTooltip>
        </BaseBadge>
        <BaseBadge
          v-if="application.applied_at"
          class="!p-0 !text-body-xs !font-semibold"
          dense
          icon="user01"
          color="transparent"
          data-testid="applied-at-badge"
        >
          {{ getTimeDifference(application.applied_at) }}
        </BaseBadge>
      </div>
      <div class="grow"></div>
      <div
        v-if="applicantCardSettings?.showOnCards.items.action.value"
        class="flex gap-[4px]"
      >
        <BaseButton
          round
          icon-prepend="share07"
          class="icon-tertiary-gray border border-proxify-gray-300"
          icon-size="12px"
          size="8px"
          data-testid="share-link-button"
          @click.stop.prevent="emit('open-share-link-dialog', application.id)"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.card {
  @apply hover:shadow-md
  cursor-pointer
  flex
  px-4
  pb-4
  flex-col
  gap-3
  rounded-xl
  border
  border-proxify-gray-200
  bg-white
  shadow-sm;
}

.title {
  display: flex;
  align-items: center;
  gap: 8px;
}
</style>
