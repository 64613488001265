<template>
  <div>
    <PDialog
      v-model="isScorecardDialogOpen"
      full-height
      maximized
      half-width
      persistent
    >
      <ApplicantActivityInterviewScorecardLayout
        ref="scoreCard"
        :application-data="data"
        :codility-score="codilityScore"
        :interview="selectedInterview"
        @open-reschedule-dialog="openRescheduleDialog"
        @close="closeScorecardDialog"
      />
    </PDialog>

    <PDialog
      v-model="isRescheduleDialogOpen"
      position="standard"
      persistent
    >
      <RescheduleCard
        :interview-id="selectedInterview.id"
        :interview-name="selectedInterview.name"
        :interviewers="interviewers"
        :initial-interviewer="selectedInterview.interviewer"
        :recruiters="recruiters"
        :close-reschedule-dialog="closeRescheduleDialog"
        :on-reschedule="onReschedule"
        :is-no-show-flow="rescheduleReason === 'no-show'"
      />
    </PDialog>

    <div
      class="interviews-container"
      :class="{ 'justify-center': isEmpty }"
    >
      <AppSkeletonLoader
        v-if="isLoading"
        wrapper-height="16.5rem"
      />
      <AppTextMessage
        v-else-if="isEmpty"
        class="mx-auto"
        value-class="text-proxify-gray-500 font-normal text-body-sm"
      >
        No interviews scheduled with this candidate yet
      </AppTextMessage>
      <div
        v-else
        class="flex gap-[1.125rem] flex-col"
      >
        <ApplicantActivityInterviewCard
          v-for="interview in interviews"
          :key="interview.id"
          :interview="interview"
          :recruiter-id="data?.recruiter_id ?? data?.assignees?.recruiter?.id"
          :open-reschedule-dialog="openRescheduleDialog"
          :open-scorecard-dialog="openScorecardDialog"
        />
      </div>
    </div>
  </div>
</template>

<script>
import RescheduleCard from '@/components/Elements/Applicant/ApplicantActivityInterviewRescheduleCard.vue';
import ApplicantActivityInterviewScorecardLayout from '@/components/Elements/Applicant/ApplicantActivityInterviewScorecardLayout.vue';
import { ref } from 'vue';
import { mapState } from 'vuex';
import ApplicantActivityInterviewCard from '@/components/Elements/Applicant/ApplicantActivityInterviewCard.vue';
import { PDialog } from '@/components/ProxifyUI';

export default {
  name: 'ApplicantActivityInterviewList',
  components: {
    ApplicantActivityInterviewCard,
    RescheduleCard,
    PDialog,
    ApplicantActivityInterviewScorecardLayout,
  },
  props: {
    getApplicantInterviews: {
      type: Function,
      required: true,
    },
    onReject: {
      type: Function,
      required: false,
    },
    interviews: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
    interviewers: {
      type: Object,
      required: false,
      default() {
        return { interviewers: [] };
      },
    },
    onReschedule: {
      type: Function,
      required: false,
    },
    isLoading: {
      type: Boolean,
      required: false,
    },
    recruiters: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
    data: {
      type: Object,
      required: true,
    },
    codilityScore: {
      type: Number,
      required: false,
    },
    skills: {
      type: Array,
      required: false,
    },
  },
  setup() {
    const scoreCard = ref(null);

    return {
      scoreCard,
    };
  },
  data() {
    return {
      selectedInterview: null,
      isScorecardDialogOpen: false,
      isRescheduleDialogOpen: false,
      rescheduleReason: '',
    };
  },
  computed: {
    ...mapState({
      currentUser: (state) => state.auth.user,
    }),
    isEmpty() {
      return !this.interviews.length;
    },
  },
  methods: {
    closeScorecardDialog() {
      this.isScorecardDialogOpen = false;
    },
    closeRescheduleDialog() {
      this.isRescheduleDialogOpen = false;
    },
    openScorecardDialog(interview) {
      this.selectedInterview = interview;
      if (!interview.karatInterview) {
        this.isScorecardDialogOpen = true;
      }
    },
    openRescheduleDialog({ interview, reason }) {
      this.rescheduleReason = '';
      this.selectedInterview = interview;
      if (reason) {
        this.rescheduleReason = reason;
      }
      this.isRescheduleDialogOpen = true;
    },
  },
};
</script>

<style scoped>
.interviews-container {
  @apply grow overflow-y-auto overflow-x-hidden flex flex-col h-full p-4;
}

.interviews-title {
  @apply text-proxify-black text-heading-3 text-opacity-80 mt-5 mb-4 ml-4 w-full;
}
</style>
