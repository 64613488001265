// eslint-disable-next-line scss/css-ruleorselectorexpected

<script setup>
import BaseIcon from '@/components/Base/BaseIcon.vue';
import BaseTooltip from '@/components/Base/BaseTooltip.vue';
import PBadge from '@/components/ProxifyUI/PBadge.vue';
import { useChangeCase } from '@vueuse/integrations/useChangeCase';
import { ref, watch } from 'vue';

const props = defineProps({
  arrowPosition: {
    type: String,
    default: 'top',
    validator: (value) =>
      [
        'none',
        'bottom left',
        'bottom center',
        'bottom right',
        'top',
        'left',
        'right',
      ].includes(value),
  },
  appendIcon: {
    type: String,
    required: false,
  },
  avatar: {
    type: String,
    required: false,
  },
  clickable: {
    type: Boolean,
    default: false,
  },
  color: {
    type: String,
    default: 'PrimaryLight',
    validator: (value) =>
      [
        'PrimaryDark',
        'PrimaryLight',
        'PrimaryMedium',
        'SecondaryLight',
      ].includes(useChangeCase(value, 'pascalCase').value),
  },
  dense: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
    required: false,
  },
  outline: {
    type: Boolean,
    default: false,
  },
  prependIcon: {
    type: String,
    required: false,
  },
  selected: {
    type: Boolean,
    default: false,
  },
  size: {
    type: String,
    default: 'sm',
  },
  state: {
    type: String,
    default: 'default',
  },
  tooltip: {
    type: String,
    required: false,
  },
  iconSize: {
    type: String,
    required: false,
  },
});

const colorClass = ref();
const tooltipTargetElement = ref();

const setColorClass = () => {
  const defaultClass = props.outline
    ? `p-badge--${useChangeCase(props.color, 'kebabCase').value}-outline`
    : `p-badge--${useChangeCase(props.color, 'kebabCase').value}`;
  if (props.selected) {
    return `${defaultClass} selected`;
  } else if (props.disabled) {
    return `${defaultClass} disabled`;
  }
  return `${defaultClass}`;
};

watch(
  () => props.selected,
  () => {
    colorClass.value = setColorClass();
  },
  { immediate: true }
);

const iconSizes = {
  sm: '10px',
  md: '12px',
  lg: '14px',
  xl: '14px',
};
</script>
<template>
  <PBadge
    :clickable="clickable"
    :dense="dense"
    :outline="outline"
    :size="size"
    :state="state"
    :class="colorClass"
    class="font-inter"
  >
    <template #prepend>
      <slot name="prepend">
        <BaseIcon
          v-if="prependIcon"
          :name="prependIcon"
          :size="iconSize ?? iconSizes[size]"
        />
      </slot>
    </template>
    <slot>{{ label }}</slot>
    <template #append>
      <slot name="append">
        <BaseIcon
          v-if="appendIcon"
          ref="tooltipTargetElement"
          :name="appendIcon"
          :size="iconSizes[size]"
        />
        <BaseTooltip
          v-if="tooltip"
          :offset="[0, 6]"
          :target="tooltipTargetElement"
          :arrow-position="arrowPosition"
        >
          {{ tooltip }}
        </BaseTooltip>
      </slot>
    </template>
  </PBadge>
</template>
<style lang="scss" scoped>
.p-badge {
  @apply transition
  duration-300
  cursor-pointer;

  &--primary-light {
    @apply bg-[#f4f5f7]
    text-[#475467];
    &:not(.selected) {
      &:hover {
        @apply bg-[#e1cdff]
        text-[#171559];
      }
    }
    &.selected {
      @apply bg-[#1d2939]
      text-[#ffffff]
      font-bold;
    }
    &.disabled {
      @apply bg-[#f9fafb]
      text-[#d0d5dd];
    }
  }

  &--secondary-light {
    @apply bg-[#f5f5ff]
    text-[#475467];

    &:not(.selected) {
      &:hover {
        @apply bg-[#e1cdff]
        text-[#171559];
      }
    }
    &.selected {
      @apply bg-[#1d2939]
      text-[#ffffff]
      font-bold;
    }
    &.disabled {
      @apply bg-[#f9fafb]
      text-[#d0d5dd];
    }
  }

  &--primary-medium {
    @apply bg-[#ffffff]
    text-[#475467];

    &:not(.selected) {
      &:hover {
        @apply bg-[#e1cdff]
        text-[#171559];
      }
    }
    &.selected {
      @apply bg-[#c084fc]
      text-[#171559]
      font-bold;
    }
    &.disabled {
      @apply bg-[#777fff]
      text-[#5258fb];
    }
  }

  &--primary-dark {
    @apply bg-[#ffffff]
    text-[#475467];

    &:not(.selected) {
      &:hover {
        @apply bg-[#e1cdff]
        text-[#171559];
      }
    }
    &.selected {
      @apply bg-[#c084fc]
      text-[#171559]
      font-bold;
    }
    &.disabled {
      @apply bg-[#272594]
      text-[#3c3ed4];
    }
  }

  &--primary-light-outline {
    @apply bg-[#ffffff]
    text-[#475467];

    &:not(.selected) {
      &:hover {
        @apply text-[#5258FB];
      }
    }
    &.selected {
      @apply bg-[#1d2939]
      text-[#ffffff]
      font-bold #{!important};
    }
    &.disabled {
      @apply text-[#d0d5dd];
    }
  }
}
</style>
