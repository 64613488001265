<script setup>
import { computed, ref, toRefs, onMounted, watch } from 'vue';
import { useStore } from 'vuex';
import { applicationUtils, browserUtils, dateTimeUtils } from '@/utils';
import useTimeRemaining from '@/composables/useTimeRemaining';
import {
  BaseApplicantBio,
  BaseBadge,
  BaseButton,
  BaseMenu,
  BaseTooltip,
  BaseIcon,
} from '@/components/Base';
import ApplicantSkillBadge from '@/components/Elements/Applicant/ApplicantSkillBadge.vue';
import { vElementHover } from '@vueuse/components';
import { computedEager, useBreakpoints } from '@vueuse/core';

const props = defineProps({
  interview: {
    type: Object,
    required: true,
  },
  openRescheduleDialog: {
    type: Function,
    required: true,
  },
  openScorecardDialog: {
    type: Function,
    required: true,
  },
});

const { interview } = toRefs(props);

const { timeRemaining } = useTimeRemaining(interview.value.calendarEvent);
const interviewTime = computedEager(() => {
  const { calendarEvent } = interview.value;
  return dateTimeUtils.formatDateTimeString(
    calendarEvent.date,
    calendarEvent.start_at,
    calendarEvent.timezone,
    'HH:mm'
  );
});

const interviewRow = ref(null);

const store = useStore();
const isNotificationBarActive = computed(
  () => store.state.settings.isNotificationBarActive
);
const isTodosMenuActive = computed(() => store.state.todos.isTodosMenuActive);

const breakpoints = useBreakpoints({
  sm: '600px',
  md: '900px',
  lg: '1200px',
  xl: '1440px',
  xxl: '1600px',
});

const xxlAndSmaller = breakpoints.smallerOrEqual('xxl');

const interviewStatus = computed(() => {
  const status = [
    {
      label: 'No show',
      condition: interview.value.cancelling_reason === 'No Show',
      color: 'error',
      active: false,
      focused: false,
    },
    {
      label: 'Rescheduled',
      condition: interview.value.rescheduling_reason,
      color: 'error',
      active: false,
      focused: false,
    },
    {
      label: 'Cancelled',
      condition:
        interview.value.cancelling_reason ||
        interview.value.calendarEvent?.status === 'cancelled',
      color: 'error',
      active: false,
      focused: false,
    },
    {
      label: 'Occurring',
      condition:
        timeRemaining.value?.timeUntilStart <= 0 &&
        timeRemaining.value?.timeUntilEnd > 0,
      color: 'primary',
      active: true,
      focused: true,
    },
    {
      label: 'Attended',
      condition: timeRemaining.value?.timeUntilEnd <= 0,
      color: 'success',
      active: false,
      focused: false,
    },
    {
      label: timeRemaining.value?.label,
      condition: timeRemaining.value?.duration?.asMinutes?.() <= 10,
      color: 'primary',
      active: true,
      focused: true,
    },
    {
      label: 'Upcoming',
      condition: timeRemaining.value?.timeUntilStart > 0,
      color: 'primary',
      active: true,
      focused: false,
    },
  ];
  return status.find(({ condition }) => condition);
});

const buttonStatus = computed(() => {
  const status = [
    {
      label: 'View Scorecard',
      condition: Boolean(interview.value.published_at),
      class: 'icon-link-gray',
      icon: 'file-check03',
      onClick: handleOpenScorecardDialog,
    },
    {
      label: 'Fill Scorecard',
      condition:
        !interview.value.published_at &&
        interviewStatus.value?.label === 'Attended' &&
        !interview.value.cancelling_reason,
      class: 'icon-link-color',
      icon: 'file05',
      onClick: handleOpenScorecardDialog,
    },
    {
      label: 'Interview Mode',
      condition: interviewStatus.value?.focused,
      class: 'icon-primary',
      icon: 'link-external01',
      onClick: handleOpenInterviewMode,
    },
    {
      label: 'Open Scorecard',
      condition: interviewStatus.value?.label === 'Upcoming',
      class: 'icon-secondary-gray',
      icon: 'file05',
      onClick: handleOpenScorecardDialog,
    },
    {
      label: null,
      condition:
        interview.value.cancelling_reason ||
        interview.value.rescheduling_reason,
      class: 'icon-tertiary-gray pointer-events-none',
      icon: 'minus',
    },
  ];

  return status.find(({ condition }) => condition);
});

const fastTrackChoice = computedEager(() => {
  const map = {
    ['ta-first']: 'TA First',
    ['screening-first']: 'Screening First',
  };
  if (!interview.value.application.fast_tracked) return 'Gray zone';
  return map[interview.value.application.fast_track_choice] ?? 'Undecided';
});

const separatedSkills = computed(() => {
  return applicationUtils.separateSkills(
    interview.value.application.application_skills
  );
});

const highDemandSecondarySkillExists = computed(() => {
  return applicationUtils
    .separateSkills(interview.value.application.application_skills)
    .secondarySkills.some(
      (skill) =>
        skill.years >= 4 && skill.supply_demand_ratio.label === 'High demand'
    );
});

const handleOpenInterviewMode = () => {
  browserUtils.createNewWindow({
    url: `${import.meta.env.VITE_URL}/applications/${interview.value.application.id}/scorecard/${interview.value.id}`,
    preset: 'half-right',
  });
};

const handleOpenRescheduleDialog = () => {
  props.openRescheduleDialog({
    interview: interview.value,
    reason: null,
  });
};
const handleOpenRescheduleDialogNoShow = () => {
  props.openRescheduleDialog({
    interview: interview.value,
    reason: 'no-show',
  });
};
const handleOpenScorecardDialog = () => {
  interviewStatus.value.label !== 'Cancelled' &&
    props.openScorecardDialog(interview.value);
};

const menuOptions = computedEager(() => {
  const rescheduleOption = {
    label: 'Reschedule',
    type: 'item',
    props: {
      clickable: true,
      onClick: handleOpenRescheduleDialog,
      closeOnClick: true,
      disable: Boolean(interview.value.rescheduling_reason),
    },
    subLabel: 'Possible after interview confirmed.',
  };
  const markAsNoShowOption = {
    label: 'Mark as no show',
    type: 'item',
    props: {
      clickable: true,
      onClick: handleOpenRescheduleDialogNoShow,
      disable: Boolean(
        ['No Show', 'Upcoming'].includes(interviewStatus.value.label) ||
          interview.value.rescheduling_reason ||
          interview.value.cancelling_reason
      ),
      closeOnClick: true,
    },
    subLabel: 'Possible after interview starts.',
  };

  const createTodoOption = {
    label: 'Create a To-do',
    type: 'item',
    props: {
      clickable: true,
      onClick: () => {
        store.commit('todos/setTodosMenuStatus', {
          active: true,
          isCreateTodoActive: true,
          todo: { application: interview.value.application },
        });
      },
      closeOnClick: true,
    },
  };

  return [rescheduleOption, markAsNoShowOption, createTodoOption];
});

onMounted(() => {
  watch(
    () => interviewStatus.value.focused,
    (newValue) => {
      if (newValue) {
        interviewRow.value.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
        });
      }
    },
    { immediate: true }
  );
});
</script>

<template>
  <div
    ref="interviewRow"
    class="border rounded-lg w-fit lg:w-full h-16 min-h-16 flex items-center select-none"
    :class="{
      'bg-proxify-gray-50 border-proxify-gray-200': !interviewStatus.active,
      'bg-white border-proxify-primary-500':
        interviewStatus.active && !interviewStatus.focused,
      'bg-proxify-primary-25 border-proxify-primary-500':
        interviewStatus.focused,
      '!w-fit': xxlAndSmaller && (isNotificationBarActive || isTodosMenuActive),
    }"
  >
    <div
      class="px-4 text-body-md font-medium max-w-[86px] w-[86px] min-w-[86px]"
      :class="{
        'text-proxify-gray-500': !interviewStatus.active,
        'text-proxify-gray-900': interviewStatus.active,
      }"
    >
      {{ interviewTime }}
    </div>
    <div class="pr-4 max-w-[137px] w-[137px] min-w-[137px]">
      <BaseBadge
        :color="interviewStatus?.color"
        :icon="interviewStatus.focused ? 'dot' : ''"
        icon-class="text-proxify-primary-500"
        icon-size="8px"
      >
        {{ interviewStatus?.label }}
      </BaseBadge>
    </div>
    <router-link
      v-element-hover="(state) => (isApplicationHovered = state)"
      class="flex gap-3 px-4 max-w-[276px] w-[276px] min-w-[276px] cursor-pointer"
      :to="`/applications/${interview.application.id}`"
      target="_blank"
    >
      <BaseApplicantBio
        :avatar-url="interview.application?.avatar"
        :avatar-background-color="
          !interviewStatus.active || interviewStatus.focused ? 'white' : ''
        "
        :name="interview.application?.full_name"
        :supporting-text="interview.application?.competency"
        name-class="text-body-sm font-semibold text-proxify-gray-700 truncate w-full"
        supporting-text-class="text-body-sm font-normal text-proxify-gray-600 truncate w-full"
        text-wrapper-class="flex flex-col justify-center items-start overflow-hidden"
        dense
        underline-when-hovered
        enable-avatar-letters
        class="max-w-[276px]"
      />
    </router-link>
    <div class="px-4 w-[243px] min-w-[243px] max-w-[243px]">
      <div class="flex gap-[4px] items-center flex-wrap">
        <ApplicantSkillBadge
          v-for="(item, index) in separatedSkills.mainSkills"
          :key="index"
          :skill="item"
          :show-proficiency="false"
          :show-experience="false"
          class="max-w-[168px] !px-2.5 !py-0.5"
          :class="{
            '!bg-white': !interviewStatus.active || interviewStatus.focused,
          }"
          label-class="!text-body-xs"
        />
        <BaseBadge
          v-if="separatedSkills.secondarySkills.length"
          badge-class="max-w-[168px] !px-2.5 !py-0.5 !text-body-xs cursor-default"
          :class="{
            '!bg-white': !interviewStatus.active || interviewStatus.focused,
          }"
        >
          <template #append>
            <BaseBadge
              v-if="highDemandSecondarySkillExists"
              size="sm"
              icon="chevron-up-double"
              color="success"
              icon-position="left"
              class="capitalize !text-body-sm !px-0 bg-transparent"
            />
            <BaseIcon
              name="plus"
              class="-mx-1.5"
              :class="{
                'text-proxify-success-600': highDemandSecondarySkillExists,
              }"
            />
            <span
              :class="{
                'text-proxify-success-600': highDemandSecondarySkillExists,
              }"
            >
              {{ separatedSkills.secondarySkills.length }}
            </span>
            <BaseTooltip
              :offset="[0, 6]"
              tooltip-class="!max-h-[unset]"
            >
              <div class="font-semibold my-1">
                Secondary Skills ({{ separatedSkills.secondarySkills.length }})
              </div>
              <div class="flex flex-col gap-[8px]">
                <ApplicantSkillBadge
                  v-for="(item, index) in separatedSkills.secondarySkills"
                  :key="index"
                  :skill="item"
                />
              </div>
            </BaseTooltip>
          </template>
        </BaseBadge>
      </div>
    </div>

    <div class="px-4 max-w-[183px] w-[183px] min-w-[183px]">
      <BaseBadge
        class="!bg-proxify-gray-50 !text-proxify-gray-700"
        :class="{
          '!px-1.5 !py-1': interview.application.fast_tracked,
          '!bg-white': !interviewStatus.active || interviewStatus.focused,
        }"
      >
        <template #prepend>
          <div
            v-if="interview.application.fast_tracked"
            class="bg-proxify-error-200 text-proxify-error-600 w-6 h-6 rounded-full flex items-center justify-center"
          >
            F
          </div>
        </template>
        {{ fastTrackChoice }}
      </BaseBadge>
    </div>
    <div class="px-4 max-w-[252px] w-[252px] min-w-[252px]">
      <BaseButton
        v-if="buttonStatus"
        :icon-prepend="buttonStatus.icon"
        icon-size="20px"
        class="w-full px-0 py-2.5 text-body-sm font-semibold"
        :class="buttonStatus.class"
        rounded
        @click="buttonStatus.onClick()"
      >
        {{ buttonStatus.label }}
      </BaseButton>
    </div>
    <div class="px-4 max-w-[72px] w-[72px] min-w-[72px]">
      <BaseButton
        icon-prepend="dots-vertical"
        round
        class="icon-tertiary-gray h-[40px] w-[40px]"
        :class="{
          'hover:!bg-white': interviewStatus.focused || !interviewStatus.active,
        }"
        size="13px"
      />

      <BaseMenu :items="menuOptions" />
    </div>
  </div>
</template>
