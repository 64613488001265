<template>
  <div class="time-selector-container">
    <div class="relative">
      <div class="time-selector-bg-layout">
        <span class="font-medium opacity-0">{{ selectedTime }}</span>
        <span class="ghost">{{ ghost }}</span>
        <span class="timezone" :class="{ 'ml-2': selectedTime }">
          {{ timezone }} time
        </span>
      </div>
      <input
        ref="selectedTime"
        v-model="selectedTime"
        maxlength="5"
        size="5"
        class="time-selector-input"
        :class="{ 'text-ats-red': !isSelectedTimeValid }"
        @input="handleSelectedTimeInput"
        @keydown.up="setActiveTimeSlot(false)"
        @keydown.down="setActiveTimeSlot"
        @keydown.enter="() => handleTimeChange()"
      />
      <IconBase
        v-if="selectedTime"
        class="absolute top-5 right-4 text-proxify-black cursor-pointer"
        height="0.8"
        width="0.8"
        @click="clearSelectedTimeInput"
      >
        <IconCross />
      </IconBase>
    </div>

    <ul
      v-if="isSelectedTimeValid"
      class="time-slots-list ats-scrollbar-persistent"
    >
      <li
        v-for="(timeSlot, index) in timeSlots"
        :id="`timeSelectorOption_${index}`"
        :key="index"
        data-type="option"
        class="option-item"
        :class="{
          'bg-proxify-gray-50 !text-proxify-midnight-blue':
            ((timeSlot === selectedTime && !activeTimeSlot) ||
              timeSlot === activeTimeSlot) &&
            !isTimeSlotDisabled(timeSlot),
          'hover:bg-proxify-gray-50 !hover:text-proxify-midnight-blue cursor-pointer':
            timeSlot !== activeTimeSlot && !isTimeSlotDisabled(timeSlot),
          'cursor-not-allowed text-proxify-gray-300':
            isTimeSlotDisabled(timeSlot),
        }"
        @click="!isTimeSlotDisabled(timeSlot) && handleTimeChange(timeSlot)"
      >
        {{ timeSlot }}
      </li>
    </ul>

    <ul v-else class="time-slots-list">
      <li class="mx-4 text-ats-red">Please enter a valid time</li>
    </ul>
  </div>
</template>

<script>
import IconBase from '@/components/Icons/IconBase.vue';
import IconCross from '@/components/Icons/IconCross.vue';
import { dateTimeUtils } from '@/utils';
import moment from 'moment';

const { getTimezone } = dateTimeUtils;

export default {
  name: 'TimePicker',
  components: {
    IconBase,
    IconCross,
  },
  props: {
    selectedDate: {
      type: Object,
      required: false,
    },
    pickTime: {
      type: Function,
      required: false,
    },
  },
  data() {
    return {
      activeTimeSlot: null,
      isSelectedTimeValid: true,
      selectedTime: '',
      timezone: getTimezone(),
    };
  },
  computed: {
    // Determines the ghost value for the selected time.
    ghost() {
      if (!this.isSelectedTimeValid) {
        return '';
      }
      const timeLength = this.selectedTime?.length;

      // Extract the last character of the selected time.
      const { [3]: lastChar } = this.selectedTime;
      // Check whether the last character of the selected time is either "1" or "4".
      const endsWithOneOrFour = ['1', '4'].includes(lastChar);

      const ghostMap = {
        1: '0:00',
        2: ':00',
        3: '00',
        4: endsWithOneOrFour ? '5' : '0',
      };
      // Return the ghost value corresponding to the length of the selected time,
      // or an empty string if there is no corresponding ghost value.
      return ghostMap[timeLength] || '';
    },
    // Returns an array of disabled time slots based on the selected date and
    // time slots.
    disabledTimeSlots() {
      // If the selected date is not today, there are no disabled time slots.
      if (!moment().isSame(this.selectedDate, 'day')) {
        return [];
      }

      // Determine the index of the selected time slot (rounded to the nearest
      // 15 minutes).
      const slotIndex = this.timeSlots.indexOf(this.roundToNearest(15));

      // If the selected time slot is not in the list of time slots, return all
      // time slots before the selected time.
      if (slotIndex === -1) {
        return this.timeSlots.filter((slot) => slot < this.roundToNearest(15));
      }

      // Otherwise, return all time slots before the selected time slot.
      return this.timeSlots.slice(0, slotIndex);
    },
    // Returns an array of time slots based on the selected time.
    timeSlots() {
      // Extract the selected hour and quarter from the selected time.
      const formattedTime = this.selectedTime + this.ghost;
      const selectedHour = parseInt(this.selectedTime?.substring(0, 2));
      const selectedQuarter = Math.floor(
        parseInt(formattedTime.substring(3, 5)) / 15
      );

      // There are 24 hours in a day, and each hour can be divided into four
      // 15-minute intervals.
      // This gives a total of 24 * 4 = 96 15-minute intervals in a day.
      // Use Array.from() with a length of 96 to generate an array of all
      // 15-minute time slots in a day.
      const allSlots = Array.from({ length: 96 }, (_, index) => {
        const hour = Math.floor(index / 4);
        const quarter = index % 4;
        const hourString = hour.toString().padStart(2, '0');
        const quarterString = (quarter * 15).toString().padStart(2, '0');
        return `${hourString}:${quarterString}`;
      });

      // If the selected time is empty, return all time slots.
      if (!this.selectedTime) {
        return allSlots;
      }

      // Filter the time slots to select only the slots that fall within a 4-slot
      // window after the selected time.
      // Return the selected time slots.
      // Example output: ["15:00", "15:15", "15:30", "15:45"]
      return allSlots.filter((slot) => {
        const slotHour = parseInt(slot.substring(0, 2));
        const slotQuarter = Math.floor(parseInt(slot.substring(3, 5)) / 15);
        return (
          slotHour === selectedHour &&
          slotQuarter >= selectedQuarter &&
          slotQuarter < selectedQuarter + 4
        );
      });
    },
  },
  mounted() {
    this.$refs.selectedTime.focus();
    this.scrollToTimeSlot();
  },
  methods: {
    clearSelectedTimeInput() {
      this.selectedTime = '';
      this.pickTime('');
      this.$refs.selectedTime.focus();
    },
    validateTime(time) {
      // Define an object that maps input lengths to corresponding input handlers
      const inputHandlers = {
        0: () => {
          return true;
        },
        1: () => {
          // Check if the input is a number
          // If it's not a number, return false
          return !isNaN(parseInt(time));
        },
        2: () => {
          // Check if the input is a valid hour value
          const hours = parseInt(time);
          // If it's not a valid hour value, return false
          return !(isNaN(hours) || hours < 0 || hours > 23);
        },
        3: () => {
          // Check if the last digit of the input is valid
          const lastDigit = time?.[2];
          // If it's not valid, return false
          return ['0', '1', '3', '4', ':'].includes(lastDigit);
        },
        4: () => {
          // Check if the last digit of the input is valid
          const lastDigit = time?.[3];
          // If it's not valid, return false
          return ['0', '1', '3', '4'].includes(lastDigit);
        },
        5: () => {
          // Check if the input is a valid time slot and if the input is disabled
          // If it's not valid, and it's disabled, return false
          return (
            !this.isTimeSlotDisabled(time) && this.timeSlots.includes(time)
          );
        },
      };

      return inputHandlers[time.length]?.() ?? false;
    },
    formatTime(time) {
      const addColon = () => {
        // Add a colon after the first two characters in the input
        return `${time.replace(':', '').substring(0, 2)}:${time
          .replace(':', '')
          .substring(2)}`;
      };
      // Define an object that maps input lengths to corresponding formatters
      const formatters = {
        1: () => {
          // Add a leading zero if the input is greater than or equal to 3
          if (parseInt(time) >= 3) {
            return `0${time}`;
          }
          // Otherwise, do not format and scroll to the appropriate time slot
          // based on the first digit of the input
          this.scrollToTimeSlot(`${time}0:00`);
          return time;
        },
        3: () => addColon(),
        4: () => addColon(),
        5: () => addColon(),
      };
      return formatters[time.length]?.() ?? time;
    },
    handleSelectedTimeInput() {
      this.isSelectedTimeValid = this.validateTime(this.selectedTime);
      this.selectedTime = this.formatTime(this.selectedTime);
      this.scrollToTimeSlot(this.selectedTime);

      if (
        this.isSelectedTimeValid &&
        !this.isTimeSlotDisabled(this.selectedTime) &&
        this.selectedTime.length === 5
      ) {
        this.pickTime(`${this.selectedTime} ${this.timezone}`);
      }
    },
    handleTimeChange(timeSlot = this.activeTimeSlot) {
      if (!this.isSelectedTimeValid) return;
      this.selectedTime = timeSlot;
      this.activeTimeSlot = timeSlot;
      this.pickTime(`${this.selectedTime} ${this.timezone}`);
      this.$refs.selectedTime.focus();
    },
    isTimeSlotDisabled(timeSlot) {
      return this.disabledTimeSlots.includes(timeSlot);
    },
    roundToNearest(interval) {
      return moment()
        .minute(interval * Math.ceil(moment().minute() / interval))
        .format('HH:mm');
    },
    scrollToTimeSlot(time) {
      const nearest30 = time || this.roundToNearest(30);
      const slotIndex = this.timeSlots.indexOf(nearest30);
      setTimeout(() => {
        document
          .getElementById(`timeSelectorOption_${slotIndex}`)
          ?.scrollIntoView?.({ behavior: 'smooth' });
      }, 0);
    },
    setActiveTimeSlot(desc = true) {
      const emptySlots = this.timeSlots.filter(
        (slot) => !this.disabledTimeSlots.includes(slot)
      );
      const currentIndex = emptySlots.indexOf(this.activeTimeSlot);
      const newIndex = desc ? currentIndex + 1 : currentIndex - 1;
      this.activeTimeSlot = emptySlots[newIndex] ?? emptySlots[0];
      this.scrollToTimeSlot(this.activeTimeSlot);
    },
  },
};
</script>

<style scoped>
.time-selector-container {
  @apply w-64
  bg-white
  border-l
  border-proxify-gray-200
  text-proxify-black
  select-none
  overflow-hidden;

  align-self: flex-start;
}

.option-item {
  @apply px-2
  py-2.5
  mx-1.5
  my-0.5
  relative
  !min-h-[42px]
  rounded-[100px]
  text-body-md
  font-medium;
}

.time-selector-bg-layout {
  @apply px-[14px]
  py-2.5
  h-[44px]
  w-full
  bg-white
  text-body-md
  border-b
  border-proxify-gray-300;
}

.time-selector-bg-layout .ghost,
.time-selector-bg-layout .timezone {
  @apply font-medium
  text-disabled-dark/50;
}

.time-selector-input {
  @apply absolute
  top-0
  bg-transparent
  h-[44px]
  w-full
  focus:outline-none
  placeholder-disabled-dark
  placeholder-opacity-50
  rounded-t
  text-body-md
  font-medium
  border-b
  border-proxify-gray-300
  px-[14px]
  py-2.5;

  box-shadow: 0 1px 2px 0 rgb(16 24 40 / 5%);
}

.time-slots-list {
  @apply min-h-0
  max-h-[344px]
  overflow-y-scroll;
}
</style>
