<template>
  <div
    ref="valueSelectorWrapper"
    class="value-selector-wrapper"
  >
    <AppMenu
      v-model:is-menu-active="active"
      :model-value="value"
      :handle-change="handleChange"
      :target-element="targetElement ?? trigger"
      :platform-element="platformElement"
      :related-elements="relatedElements"
      :menu-placement="menuPlacement"
      :positioning-strategy="positioningStrategy"
      :menu-offset-x="menuOffsetX"
      :menu-offset-y="menuOffsetY"
      :menu-placement-spacing="menuPlacementSpacing"
      :options="options"
      :is-loading="isLoading"
      :return-object="returnObject"
      :item-value="itemValue"
      :item-text="itemText"
      :multiple="multiple"
      has-search-input
      :has-leading-icon="options.length"
      has-trailing-icon
      :has-title="hasTitle"
      :placeholder="placeholder"
      :has-create-button="showCreateButton"
      :has-other-option="hasOtherOption"
      highlight-value="risky"
      highlight-class="text-ats-red"
      :close-on-click="closeOnClick"
      :on-close="onClose"
      :container-width="containerWidth"
    >
      <template #activator="{ toggle }">
        <div class="flex flex-row items-center">
          <slot name="prepend"></slot>
          <ValueSelectorTrigger
            v-if="!hideTrigger"
            ref="trigger"
            :data-testid="`${name.toLowerCase().replaceAll(' ', '-')}-data`"
            :name="name"
            :active="active"
            :contained="contained"
            :required="required"
            :model-value="returnObject ? value?.[itemText] : value"
            :initial-value="initialValue"
            :items="options"
            :item-text="itemText"
            :on-toggle="toggle"
            :initial-class="initialClass"
          />
        </div>
      </template>
      <template #optionPrefix>
        <slot name="optionPrefix"></slot>
      </template>
      <template #footer>
        <slot name="footer"></slot>
      </template>
    </AppMenu>
  </div>
</template>

<script>
import ValueSelectorTrigger from '@/components/Elements/Scaffold/ValueSelectorTrigger.vue';
import { useComputedPositions } from '@/composables/useComputedPositions';
import { computed, ref, toRefs, watch } from 'vue';

export default {
  name: 'ValueSelector',
  components: {
    ValueSelectorTrigger,
  },
  props: {
    name: {
      type: String,
      default() {
        return '';
      },
    },
    targetElement: {
      required: false,
    },
    platformElement: {
      required: false,
    },
    relatedElements: {
      required: false,
    },
    menuPlacement: {
      type: String,
      required: false,
      default() {
        return 'right-start';
      },
    },
    positioningStrategy: {
      type: String,
      required: false,
    },
    menuOffsetX: {
      type: Number,
      required: false,
    },
    menuOffsetY: {
      type: Number,
      required: false,
    },
    menuPlacementSpacing: {
      type: Number,
      required: false,
    },
    containerHeight: {
      type: [String],
      default() {
        return '';
      },
    },
    containerWidth: {
      type: [String],
      default() {
        return '16rem';
      },
    },
    valueSelectorWrapperClass: {
      type: String,
      default() {
        return 'w-full';
      },
    },
    isMenuActive: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
    triggerType: {
      type: String,
      required: false,
      default() {
        return 'inline';
      },
    },
    card: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
    type: {
      type: String,
      required: false,
      default: 'filter',
    },
    value: {
      type: [String, Object, Number],
      required: false,
    },
    options: {
      type: Array,
      required: true,
    },
    disabledOptions: {
      type: Array,
      required: false,
    },
    placeholder: {
      type: String,
      required: false,
      default() {
        return 'Search...';
      },
    },
    hideInputIcon: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
    showCreateButton: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
    hasOtherOption: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
    onChange: {
      type: Function,
      required: true,
    },
    onClose: {
      type: Function,
      required: false,
    },
    isLoading: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
    hasTitle: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
    hideTrigger: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
    closeOnClick: {
      type: Boolean,
      default() {
        return false;
      },
    },
    returnObject: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
    itemText: {
      type: String,
      required: false,
      default() {
        return '';
      },
    },
    itemValue: {
      type: String,
      required: false,
      default() {
        return '';
      },
    },
    initialValue: {
      type: String,
      required: false,
    },
    required: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
    multiple: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
    initialClass: {
      type: String,
      required: false,
      default: 'text-proxify-gray-700',
    },
  },
  emits: ['update:isMenuActive'],
  setup(props, { emit }) {
    const valueSelectorContainer = ref(null);
    const trigger = ref(null);
    const valueSelectorWrapper = ref(null);
    const active = ref(false);
    const {
      platformElement,
      targetElement,
      menuPlacement,
      menuOffsetX,
      menuOffsetY,
      menuPlacementSpacing,
      positioningStrategy,
    } = toRefs(props);
    const { positions } = useComputedPositions(
      computed(() =>
        targetElement.value ? targetElement.value : trigger.value
      ),
      valueSelectorContainer,
      menuPlacement,
      {
        platformElement,
        offsetX: menuOffsetX.value,
        offsetY: menuOffsetY.value,
        spacingValue: menuPlacementSpacing.value,
        positioningStrategy: positioningStrategy.value,
      }
    );
    watch(
      () => props.isMenuActive,
      (newValue) => {
        active.value = newValue;
      }
    );
    watch(
      () => active.value,
      (newValue) => {
        emit('update:isMenuActive', newValue);
      }
    );
    return {
      valueSelectorContainer,
      valueSelectorWrapper,
      trigger,
      computedPositions: positions,
      active,
    };
  },
  computed: {
    inline() {
      return this.triggerType === 'inline';
    },
    contained() {
      return this.triggerType === 'contained';
    },
  },
  methods: {
    handleChange(value) {
      if (this.returnObject) {
        const selectedItem = this.options.find(
          (item) => item[this.itemValue] === value
        );
        this.onChange(selectedItem);
      } else {
        this.onChange(value);
      }
      this.input = '';
      if (this.closeOnClick) {
        this.$emit('update:isMenuActive', false);
      }
    },
  },
};
</script>
