<template>
  <div class="flex flex-col">
    <div class="text-proxify-black">{{ title }}</div>
    <div
      class="checkbox-wrapper"
      :class="{
        disabled,
        'read-only': readOnly,
        'cursor-pointer': !disableLabelClick,
        ...wrapperClasses
          .split(' ')
          .reduce((acc, cur) => ({ ...acc, [cur]: true }), {}),
      }"
      @click.self="
        () => !disabled && !disableLabelClick && handleChange(truthyValue)
      "
    >
      <div
        class="checkbox-icon-wrapper"
        :class="{ 'pointer-events-none': disabled }"
        :data-testid="
          `checkbox-icon-${name}-value-${truthyValue}`
            .toLowerCase()
            .replaceAll(' ', '-')
        "
        @click="handleChange(truthyValue)"
      >
        <div class="checkbox-icon-container">
          <IconBase
            :color="
              checked ? (!disabled ? '#5258FB' : '#979797') : 'transparent'
            "
            border-radius="0.25rem"
            class="absolute inset-0 w-full h-full p-1 cursor-pointer"
            :class="{ 'cursor-not-allowed': disabled }"
          >
            <IconCheckbox />
          </IconBase>
        </div>
      </div>
      <slot name="label">
        <div :class="checked ? 'text-proxify-black' : 'text-disabled-dark'">
          {{ label }}
        </div>
      </slot>
    </div>
    <div v-if="label === 'Other:'">
      <AppFormTextField
        v-model="inputValue"
        :required="checked"
        hide-label
        :validate-on-mount="checked"
        :label="`${name} ${label}`"
        :disabled="disabled"
        class="ml-6"
        @update:model-value="updateInput"
        @focus="handleChange(truthyValue)"
      />
    </div>
    <input
      v-model="checked"
      class="opacity-0 w-px h-px"
      type="checkbox"
      :data-testid="
        `checkbox-${name}-value-${truthyValue}`
          .toLowerCase()
          .replaceAll(' ', '-')
      "
      @input="handleChange(truthyValue)"
    />
  </div>
</template>

<script>
import IconBase from '@/components/Icons/IconBase.vue';
import IconCheckbox from '@/components/Icons/IconCheckbox.vue';
import { reactive, toRefs, watchEffect } from 'vue';
import { useField } from 'vee-validate';
import { computedEager } from '@vueuse/core';

export default {
  name: 'AppFormCheckbox',
  components: { IconCheckbox, IconBase },
  props: {
    modelValue: {
      type: [Array, String, Boolean],
      required: false,
    },
    initialValue: {
      type: [Array, String, Boolean, Object],
      required: false,
    },
    truthyValue: {
      type: [String, Boolean],
      required: false,
      default: true,
    },
    falsyValue: {
      type: [String, Boolean],
      required: false,
      default: false,
    },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: false,
    },
    required: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
    disableLabelClick: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
    disabled: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
    readOnly: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
    wrapperClasses: {
      type: String,
      required: false,
      default() {
        return '';
      },
    },
  },
  emits: ['update:modelValue', 'update:inputValue'],
  setup(props, { emit }) {
    const { name, truthyValue, falsyValue, initialValue, required } =
      toRefs(props);
    const isOpenAnswered = computedEager(() => truthyValue.value === 'Other:');
    const otherValue = computedEager(() => {
      if (Array.isArray(initialValue.value)) {
        return initialValue.value.find(
          (item) => item?.slice?.(0, 6) === 'Other:'
        );
      }
      return initialValue.value?.body ?? '';
    });
    const state = reactive({
      inputValue: '',
    });
    const updateInput = (value) => {
      emit('update:inputValue', truthyValue.value + value);
    };
    watchEffect(() => {
      if (isOpenAnswered.value && otherValue.value) {
        state.inputValue = otherValue.value.slice(6);
        updateInput(state.inputValue);
      }
    });
    const { handleChange, checked } = useField(
      name,
      { required: required.value },
      {
        type: 'checkbox',
        checkedValue: truthyValue.value,
        uncheckedValue: falsyValue.value,
        initialValue: Array.isArray(initialValue.value)
          ? initialValue.value?.map?.((item) =>
              item?.includes?.('Other:') ? 'Other:' : item
            )
          : initialValue.value?.checked ?? initialValue.value,
        syncVModel: true,
      }
    );
    return {
      ...toRefs(state),
      handleChange,
      updateInput,
      checked,
    };
  },
};
</script>
<style scoped>
.checkbox-icon-container {
  @apply absolute
  shadow-inner
  bg-[#f0f0f0]
  top-1/4
  left-1/4
  w-[50%]
  h-[50%]
  transition;
}

.checkbox-icon-wrapper {
  @apply relative w-12 h-12 min-w-12 min-h-12 select-none;
}

.checkbox-wrapper {
  @apply inline-flex items-center select-none;
}

.checkbox-wrapper.disabled {
  @apply cursor-not-allowed;
}

.checkbox-wrapper.read-only {
  @apply pointer-events-none;
}
</style>
